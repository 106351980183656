<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <el-button @click="handlePageChange(1)">刷新</el-button>
        <el-button
          class=""
          type="primary"
          @click="handleAddList"
          style="margin-right: 14px"
          >新增</el-button
        >
        <el-date-picker
          v-model="selectTime"
          style="width: 250px"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="-"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <!-- 状态 -->
        <el-select
          style="width: 100px"
          v-model="params.status"
          clearable
          placeholder="状态"
        >
          <el-option label="已接单" :value="1"> </el-option>
          <el-option label="未接单" :value="0"> </el-option>
        </el-select>
        <!-- 书籍 -->
        <el-select
          v-model="params.booksCategoryId"
          filterable
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍分类"
        >
          <el-option
            v-for="item in bookCategoryList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 素材库分类 -->
        <el-select
          v-model="params.deliveryCategoryId"
          filterable
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="素材库"
        >
          <el-option
            v-for="item in resourceCategoryList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 单独用户 -->
        <el-select
          style="width: 150px"
          v-model="params.adminUserId"
          filterable
          clearable
          placeholder="用户名称"
        >
          <el-option
            v-for="item in putUserList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 方案 -->
        <el-input
          style="width: 150px"
          v-model="params.content"
          placeholder="方案"
        ></el-input>
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>
    <!-- 表格数据 -->
    <div class="custom-table" v-loading="loading">
      <el-table
        :data="putList"
        style="width: 100%"
        height="calc(100vh - 300px)"
        ref="dataTable"
      >
        <el-table-column label="id" prop="id" min-width="50"></el-table-column>
        <el-table-column
          label="投手"
          prop="putUserName"
          min-width="50"
        ></el-table-column>
        <el-table-column label="书籍分类" min-width="60">
          <template slot-scope="scope">
            {{ getBookCategoryById(scope.row.booksCategoryId) }}
          </template>
        </el-table-column>
        <el-table-column label="书籍" min-width="60" prop="bookName">
        </el-table-column>
        <el-table-column label="素材" prop="img" width="100">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="hover" width="340">
              <template
                v-if="
                  scope.row.deliveryExampleList &&
                  scope.row.deliveryExampleList.length > 0 &&
                  scope.row.deliveryExampleList[0].imgUrl
                "
              >
                <div class="origin-img">
                  <template
                    v-for="(item, index) in scope.row.deliveryExampleList"
                  >
                    <template v-if="item.imgUrl">
                      <img :key="index" :src="item.imgUrl" alt="原图" />
                    </template>
                    <template v-else>
                      <video :key="index" controls :src="item.mediaUrl" />
                    </template>
                  </template>
                </div>
                <div class="thumbnail-img" slot="reference">
                  <img :src="scope.row.deliveryExampleList[0].imgUrl" />
                </div>
              </template>
              <template
                v-else-if="
                  scope.row.deliveryExampleList &&
                  scope.row.deliveryExampleList.length > 0 &&
                  scope.row.deliveryExampleList[0].mediaUrl
                "
              >
                <div class="origin-img">
                  <!-- <video
                    controls
                    :src="scope.row.deliveryExampleList[0].mediaUrl"
                  /> -->
                  <template
                    v-for="(item, index) in scope.row.deliveryExampleList"
                  >
                    <template v-if="item.imgUrl">
                      <img :key="index" :src="item.imgUrl" alt="原图" />
                    </template>
                    <template v-else>
                      <video :key="index" controls :src="item.mediaUrl" />
                    </template>
                  </template>
                </div>
                <div class="thumbnail-img" slot="reference">
                  <video :src="scope.row.deliveryExampleList[0].mediaUrl" />
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="素材分类">
          <template slot-scope="scope">
            <div class="line-overflow">
              {{ getResourceName(scope.row.deliveryCategoryId) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="文案" min-width="200">
          <template slot-scope="scope">
            <el-popover placement="right" title="文案" trigger="hover">
              <div>
                {{ scope.row.content }}
              </div>
              <div class="line-overflow" slot="reference">
                {{ scope.row.content }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="想法" prop="idea" min-width="100">
          <template slot-scope="scope">
            <el-popover placement="right" title="想法" trigger="hover">
              <div>
                {{ scope.row.idea }}
              </div>
              <div class="line-overflow" slot="reference">
                {{ scope.row.idea }}
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column
          label="上传时间"
          prop="createdTime"
          width="160"
        ></el-table-column>

        <el-table-column label="状态" min-width="50">
          <template slot-scope="scope">
            <el-tag :type="getTagType(scope.row)">{{
              scope.row.status === 0 ? "未接单" : "已接单"
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button class="button-small" @click="goToDetail(scope.row)"
              >详情</el-button
            >
            <el-button
              type="primary"
              class="button-small"
              @click="editSource(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              class="button-small"
              @click="deleteSelectedPage(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 新增、编辑弹框 -->
    <el-drawer
      direction="rtl"
      size="40%"
      :title="`${isEdit ? '编辑派单' : '新增派单'}`"
      :visible.sync="showEdit"
      :before-close="handleCancel"
    >
      <div>
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="100px"
        >
          <!-- 上传 -->
          <el-form-item label="素材" prop="img">
            <span style="color: #ccc; margin-left: 10px"
              >视频支持avi,mp4,mov格式</span
            >
            <input
              type="file"
              style="display: none"
              accept="image/gif, image/jpeg, image/png, image/jpg,.avi,.mp4,.mov"
              ref="imgUpload"
              @change="handleFileChange"
            />
            <div class="video-progress" v-if="showProcess">
              <el-progress
                :show-text="false"
                :percentage="videoPercent"
              ></el-progress>
            </div>
            <div class="source-box">
              <div
                class="source-img"
                v-for="(item, index) in uploadList"
                :key="index"
              >
                <video
                  v-if="item.type === 1"
                  width="180"
                  height="140"
                  controls
                  :src="item.src"
                ></video>
                <img v-else :src="item.src" alt="" />
                <div class="mask-hover">
                  <i
                    class="iconfont icon-lajitong"
                    style="color: #fff"
                    @click="handleDelSource(index)"
                  ></i>
                </div>
              </div>
              <div
                class="source-img"
                @click="uploadImg"
                v-if="!showProcess && uploadList.length < 6"
              >
                <div class="add-surce">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- 上传结束 -->
          <el-form-item
            v-if="isEdit"
            v-show="false"
            label="派单id"
            prop="deliveryId"
          >
            <el-input
              style="width: 150px"
              type="number"
              v-model="ruleForm.deliveryId"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="书籍分类"
            prop="booksCategoryId"
            class="form-books"
          >
            <div class="choose-book-categoryId">
              <el-select
                v-model="ruleForm.booksCategoryId"
                placeholder="书籍分类"
                style="width: 150px"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in bookCategoryList"
                  :key="item.id"
                >
                </el-option>
              </el-select>
              <div class="choose-book">
                <el-form-item label="书籍" label-width="50px" prop="bookId">
                  <el-select
                    v-model="ruleForm.bookId"
                    filterable
                    clearable
                    remote
                    style="width: 150px"
                    reserve-keyword
                    :remote-method="getBook"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in bookList"
                      :key="item.id"
                      :label="item.bookName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="素材分类" prop="deliveryCategoryId">
            <el-select
              v-model="ruleForm.deliveryCategoryId"
              placeholder="请选择分类"
              filterable
            >
              <el-option
                v-for="item in resourceCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文案" prop="content">
            <el-input
              style="width: 500px"
              type="textarea"
              v-model="ruleForm.content"
            ></el-input>
          </el-form-item>
          <el-form-item label="想法" prop="idea">
            <el-input
              style="width: 500px"
              type="textarea"
              v-model="ruleForm.idea"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">确定</el-button>
            <el-button type="info" @click="handleCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 详情窗口 -->
    <puter-detail
      :detailData="detailData"
      :showDetailF="showDetail"
      @closeDetail="closeDetail"
    >
    </puter-detail>
  </div>
</template>

<script>
import {
  getPutDelivery,
  addPutDelivery,
  ChangePutDelivery,
  DeletePutDelivery,
  compressUpload,
} from "@/api/novelPut";
import { getClassifyList } from "@/api/classify";
import { mapGetters } from "vuex";
import { yesterdayOptions, pickerOptions } from "@/assets/js/options";
import { getBookList } from "@/api/account";
import PuterDetail from "./puterDetail";
export default {
  data() {
    return {
      putList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      params: {},
      selectTime: [],
      bookCategoryList: [],
      resourceCategoryList: [],
      pickerOptions,
      loading: false,
      isEdit: false,
      showEdit: false,
      ruleForm: {},
      rules: {
        deliveryId: [
          {
            required: true,
            message: "请输入派单id",
            trigger: "blur",
          },
        ],
        booksCategoryId: [
          {
            required: true,
            message: "选择书籍分类",
            trigger: "blur",
          },
        ],
        deliveryCategoryId: [
          {
            required: true,
            message: "选择素材分类",
            trigger: "blur",
          },
        ],
      },
      showProcess: false,
      uploadList: [],
      bookList: [],
      detailData: {},
      showDetail: false,
    };
  },
  components: {
    PuterDetail,
  },

  computed: {
    ...mapGetters(["putUserList"]),
    getBookCategoryById() {
      return function (id) {
        if (id === 0) {
          return "所有";
        }
        let tmp = "";
        this.bookCategoryList.forEach((item) => {
          if (item.id === id) {
            tmp = item;
          }
        });
        if (tmp && tmp.name) {
          return tmp.name;
        }
      };
    },
    getResourceName() {
      return function (id) {
        if (id === 0) {
          return "所有";
        }
        let tmp = this.resourceCategoryList.find((item) => item.id === id);
        if (tmp && tmp.name) {
          return tmp.name;
        }
      };
    },
    videoPercent() {
      return this.$store.state.uploadPercent;
    },
  },
  watch: {
    videoPercent: {
      handler(newV) {
        this.showProcess = newV !== 100;
      },
    },
    "ruleForm.booksCategoryId": {
      handler(newV) {
        this.bookList = [];
      },
    },
  },
  methods: {
    handlePageChange(inPage) {
      inPage ? (this.page = inPage) : "";
      let tmpParams = {
        page: this.page,
        pageSize: this.pageSize,
        startDate: this.selectTime[0] || null,
        endDate: this.selectTime[1] || null,
        ...this.params,
      };
      this.loading = true;
      getPutDelivery(tmpParams)
        .then((res) => {
          this.putList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取书籍、素材库分类
    getCategory() {
      getClassifyList({
        type: 3,
      }).then((res) => {
        this.bookCategoryList = [
          {
            createdTime: "",
            id: 0,
            name: "所有",
          },
          ...res,
        ];
      });
      getClassifyList({ type: 5 }).then((res) => {
        this.resourceCategoryList = [
          {
            createdTime: "",
            id: 0,
            name: "所有",
          },
          ...res,
        ];
      });
    },
    // 重置按钮
    handleReset() {
      this.params = {};
      this.page = 1;
      this.pageSize = 15;
      this.selectTime = [];
      this.handlePageChange();
    },
    // 编辑操作
    editSource(row) {
      this.getBookById(row.bookId);
      this.showEdit = true;
      this.ruleForm = {};
      this.$set(this.ruleForm, "booksCategoryId", row.booksCategoryId);
      this.$set(this.ruleForm, "content", row.content);
      this.$set(this.ruleForm, "deliveryCategoryId", row.deliveryCategoryId);
      this.$set(this.ruleForm, "bookId", row.bookId);
      this.$set(this.ruleForm, "idea", row.idea);
      this.$set(this.ruleForm, "deliveryId", row.id);
      this.uploadList = [];
      if (row.deliveryExampleList) {
        row.deliveryExampleList.forEach((item) => {
          if (item.mediaUrl) {
            this.uploadList.push({
              type: 1,
              src: item.mediaUrl,
            });
          } else if (item.imgUrl) {
            this.uploadList.push({
              type: 0,
              src: item.imgUrl,
            });
          }
        });
      }
      this.isEdit = true;
    },
    // 删除操作
    deleteSelectedPage(id) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DeletePutDelivery(id).then((res) => {
          this.$message.success("删除成功");
          this.handlePageChange();
        });
      });
    },
    // 详情操作
    goToDetail(data) {
      let tmpData = {
        booksCategoryName: this.getBookCategoryById(data.booksCategoryId),
        deliveryCategoryName: this.getResourceName(data.deliveryCategoryId),
        ...data,
      };
      this.detailData = tmpData;
      this.showDetail = true;
    },

    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    // 新增派单
    handleAddList() {
      this.isEdit = false;
      this.showEdit = true;
    },
    // 删除上传视频 图片
    handleDelSource(index) {
      this.uploadList.splice(index, 1);
    },
    // 点击新增
    uploadImg() {
      this.$refs.imgUpload.click();
    },
    // 添加视频
    async handleFileChange(e) {
      const tmp = e.target ? e.target.files[0] : e.srcElement.files[0];
      const targetFile = e.target ? e.target.files[0] : e.srcElement.files[0];
      if (["video/avi", "video/mp4", "video/mov"].includes(targetFile.type)) {
        this.status = 1;
        const res = await compressUpload({ file: targetFile });
        this.uploadList.push({
          type: 1,
          src: res,
        });
        this.$refs.imgUpload.value = "";
      } else {
        this.videoSrc = "";
        this.status = 0;
        const fr = new FileReader();
        fr.readAsDataURL(targetFile);
        fr.onload = (event) => {
          const image = new Image();
          image.src = event.currentTarget.result;
          image.onload = () => {
            //   const { width, height } = image
            compressUpload({ file: targetFile }).then((res) => {
              this.uploadList.push({
                type: 0,
                src: res,
              });
              this.$refs.imgUpload.value = "";
            });
          };
        };
      }
    },
    // 提交表单
    submitForm() {
      let tmpImg = [];
      let tmpVideo = [];
      let tmpDeliveryExampleList = [];
      this.uploadList.forEach((item) => {
        if (item.type === 0) {
          tmpImg.push(item.src);
          tmpDeliveryExampleList.push({
            imgUrl: item.src,
          });
        }
        if (item.type === 1) {
          tmpVideo.push(item.src);
          tmpDeliveryExampleList.push({
            mediaUrl: item.src,
          });
        }
      });
      // 判定必传是否填选
      // if (this.uploadList.length === 0) {
      //   this.$message.error("请选择素材");
      //   return false;
      // }
      if (this.ruleForm.booksCategoryId === undefined) {
        this.$message.error("请选择书籍分类");
        return false;
      }
      if (this.ruleForm.deliveryCategoryId === undefined) {
        this.$message.error("请选择素材类");
        return false;
      }
      // if (this.ruleForm.content === undefined) {
      //   this.$message.error("请填写文案");
      //   return false;
      // }
      // 新增操作
      if (!this.isEdit) {
        addPutDelivery({
          booksCategoryId: this.ruleForm.booksCategoryId || 0,
          content: this.ruleForm.content,
          // deliveryExampleList: [
          //   { imgUrl: tmpImg.join(","), mediaUrl: tmpVideo.join(",") },
          // ],
          deliveryExampleList: tmpDeliveryExampleList,
          bookId: this.ruleForm.bookId,
          deliveryCategoryId: this.ruleForm.deliveryCategoryId,
          idea: this.ruleForm.idea,
        }).then((res) => {
          this.$message.success("新增成功");
          this.handlePageChange();
          this.ruleForm = {};
          this.uploadList = [];
          this.isEdit = false;
          this.showEdit = false;
        });
      } else {
        ChangePutDelivery(this.ruleForm.deliveryId, {
          booksCategoryId: this.ruleForm.booksCategoryId || 0,
          content: this.ruleForm.content,
          // deliveryExampleList: [
          //   { imgUrl: tmpImg.join(","), mediaUrl: tmpVideo.join(",") },
          // ],
          deliveryExampleList: tmpDeliveryExampleList,
          bookId: this.ruleForm.bookId,
          deliveryCategoryId: this.ruleForm.deliveryCategoryId,
          idea: this.ruleForm.idea,
        }).then((res) => {
          this.$message.success("修改成功");
          this.handlePageChange();
          this.ruleForm = {};
          this.uploadList = [];
          this.isEdit = false;
          this.showEdit = false;
        });
      }
    },
    // 取消提交
    handleCancel() {
      this.ruleForm = {};
      this.uploadList = [];
      this.isEdit = false;
      this.showEdit = false;
    },
    // 获取书籍列表
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
        categoryId: this.ruleForm.booksCategoryId,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getTagType(row) {
      return row.status === 0 ? "danger" : "success";
    },
  },
  created() {
    this.getCategory();
    this.handlePageChange();
  },
};
</script>

<style lang="scss" scoped>
.ad-consume{
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box{
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title{
    color: $main-text;
    margin-bottom: 20px;
    .iconfont{
      font-size: 12px !important;
    }
  }
}
.consume-table{
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
  width: 70px;
  height: 70px;
}
}
.report-cont {
  width: 500px;
  height: 690px;
  margin-right: 10px;
  border: 1px solid #ebeef5;
  border-radius: 6px;
  background-color: #fff;
  padding: 10px;
}
.book-data{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.origin-img {
  img {
    width: 100px;
    height: 100px;
    margin: 2px;
  }
  video {
    width: 100px;
    height: 100px;
    margin: 2px;
  }
}

.thumbnail-img {
  width: 64px;
  height: 36px;
  img {
    width: 100%;
    height: 100%;
  }
  video {
    width: 100%;
    height: 100%;
  }
}
.add-surce{
  cursor: pointer;
  width: 180px;
  height: 140px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  i{
    font-size: 20px;
  }
}
.source-box{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .source-img:hover{
    .mask-hover{
      display: block;
    }
  }
  .source-img{
    position: relative;
    margin-right: 5px;
    margin-top: 5px;
    width: 180px;
    height: 140px;
    img {
    width: 100%;
    height: 100%;
  }
  .mask-hover {
    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  }
  
}
.choose-book-categoryId{
  display: flex;
  align-items: center;
  .choose-book{
    height: 33px;
  }
}
</style>
<template>
  <el-drawer
    title="计划详情"
    size="90%"
    append-to-body
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="row">
      <el-date-picker
        v-model="selectTime"
        style="width: 330px; margin-right: 10px"
        type="daterange"
        align="right"
        unlink-panels
        :clearable="false"
        range-separator="-"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <el-button type="primary" @click="handleSearch">搜索</el-button>
      <el-button type="info" @click="handleReset">重置</el-button>
    </div>
    <div class="put-project-detail">
      <div>
        <div class="custom-card">
          <div class="data-label">总花费：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{ statistic.cost || 0 }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">曝光量：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.viewCount || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">点击量：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.validClickCount || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">点击率：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.ctrStr || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">转化目标量：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.validClickCount || 0
            }}</span>
          </div>
        </div>
        <div class="custom-card">
          <div class="data-label">转化目标成本：</div>
          <div class="statistic-num-text">
            <span class="custom-statistic-num">{{
              statistic.conversionsCost || 0
            }}</span>
          </div>
        </div>
      </div>
      <div class="custom-table">
        <el-table
          v-loading="loading"
          height="68vh"
          @sort-change="handleSortChange"
          style="width: 100%"
          :data="dataList"
        >
          <el-table-column label="时间" fixed="left" min-width="120">
            <template slot-scope="scope">
              <span>{{
                scope.row.date.length > 0 ? scope.row.date : "汇总"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="投手" prop="putName" fixed="left">
          </el-table-column>
          <el-table-column
            label="计划ID"
            prop="campaignId"
            fixed="left"
          ></el-table-column>
          <el-table-column
            prop="cost"
            sortable="custom"
            label="花费(元)"
            width="100"
          >
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="viewCount"
            label="曝光量"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="validClickCount"
            sortable="custom"
            label="点击量"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="ctr"
            sortable="custom"
            label="点击率"
            width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.ctrStr }}
            </template>
          </el-table-column>
          <el-table-column
            prop="conversionsCount"
            sortable="custom"
            label="目标转化量"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsCost"
            sortable="custom"
            label="目标转化成本(元)"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="conversionsRate"
            sortable="custom"
            label="目标转化率"
            min-width="150"
          >
            <template slot-scope="scope">
              {{ scope.row.conversionsRateStr }}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderRoi"
            sortable="custom"
            label="下单ROI"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="orderAmount"
            sortable="custom"
            label="下单金额"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="orderUnitPrice"
            sortable="custom"
            label="下单客单价"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="thousandDisplayPrice"
            sortable="custom"
            label="千次曝光成本"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="dailyBudget"
            sortable="custom"
            label="日预算"
            min-width="120"
          >
          </el-table-column>
        </el-table>
        <el-pagination
          style="margin: 10px 0; text-align: right"
          background
          @current-change="handlePageChange"
          :current-page="page"
          :page-size="20"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { yesterdayOptions, pickerOptions } from "@/assets/js/options";

import { getDeliveryCampaign } from "@/api/novelPut.js";
import { login } from "../../../api/user";
export default {
  props: ["info", "show"],
  name: "PutProjectDetail",
  data() {
    return {
      dataList: [],
      page: 1,
      pageSize: 15,
      pickerOptions,
      sortName: null,
      sortDesc: null,
      total: 0,
      params: {},
      statistic: {},
      selectTime: [],
      loading: false,
    };
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          if (this.info) {
            this.getNowTime();
            this.initData();
          }
        }
      },
    },
  },
  mounted() {},
  methods: {
    initData() {
      getDeliveryCampaign(this.info.id, {
        beginDate: this.selectTime[0],
        endDate: this.selectTime[1],
        dataType: 1,
        page: this.page,
        pageSize: this.pageSize,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
      }).then((res) => {
        res.list.list.unshift({
          date: "汇总",
          putName: "--",
          campaignName: "--",
          channelName: "--",
          conversionsCost: res.total.conversionsCost,
          conversionsCount: res.total.conversionsCount,
          conversionsRate: res.total.conversionsRate,
          conversionsRateStr: res.total.conversionsRateStr,
          cost: res.total.cost,
          ctr: res.total.ctr,
          ctrStr: res.total.ctrStr,
          dailyBudget: res.total.dailyBudget,
          orderAmount: res.total.orderAmount,
          orderRoi: res.total.orderRoi,
          orderUnitPrice: res.total.orderUnitPrice,
          thousandDisplayPrice: res.total.thousandDisplayPrice,
          validClickCount: res.total.validClickCount,
          viewCount: res.total.viewCount,
        });
        this.dataList = res.list.list;
        this.total = res.list.total;
        this.statistic = res.total;
      });
    },
    getNowTime() {
      let nowTime = new Date()
        .toLocaleDateString()
        .split("/")
        .map((item) => item.padStart(2, "0"))
        .join("-");
      this.selectTime = [nowTime, nowTime];
    },
    handleSortChange({ prop, order }) {
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.initData();
    },
    handlePageChange(val) {
      this.page = val;
      this.initData();
    },
    handleClose() {
      this.$emit("update:show", false);
    },
    handleSearch() {
      this.initData();
    },
    handleReset() {
      this.getNowTime();
      this.initData();
    },
  },
  //import引入的组件
  components: {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-table {
  /deep/ .el-table__header{

  /deep/ .is-leaf {
    border-bottom: 0px !important;
    height: 56px !important;
  }
  }
}
.row {
  padding-bottom: 20px;
  padding-left:20px;
}
.custom-card {
  margin-bottom: 20px;
}
.put-project-detail{
  box-sizing: border-box;
  padding:0 20px;
}

</style>

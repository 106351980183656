<template>
  <div>
    <div class="ruler">
      <div class="value-box">
        <div class="value-item">0%</div>
        <div class="value-item"></div>
        <div class="value-item">20%</div>
        <div class="value-item"></div>
        <div class="value-item">40%</div>
        <div class="value-item"></div>
        <div class="value-item">60%</div>
        <div class="value-item"></div>
        <div class="value-item">80%</div>
        <div class="value-item"></div>
        <div class="value-item">100%</div>
      </div>
    </div>
    <el-progress
      style="margin: 0 15px 0 10px"
      :percentage="percentage"
      :stroke-width="strokeWidth"
      :text-inside="true"
      :color="showColor"
      :show-text="showText"
    ></el-progress>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0
    },
    showText: {
      type: Boolean,
      default: false
    },
    strokeWidth: {
      type: Number,
      default: 15
    },
    showColor: {
      type: String,
      default: '#579BF4'
    }
  }
}
</script>

<style scoped>
.ruler {
  margin-bottom: 10px;
}
.value-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ruler-box {
  margin: 0 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.value-item {
  position: relative;
  color: #5f6e82;
  font-size: 12px;
  height: 16px;
}
.value-item::after {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  display: inline-block;
  width: 2px;
  height: 5px;
  background-color: #e0e6ed;
}
</style>

<template>
  <div
    class="put-decision-maker padding-20 border-radius-6"
    id="put-decision-maker"
  >
    <div class="putter-operation">
      <el-date-picker
        v-model="params.queryDate"
        value-format="yyyy-MM-dd"
        type="date"
        style="width: 150px; margin-right: 10px"
        :clearable="false"
        placeholder="选择时间"
        :picker-options="disableTodayOptions"
      >
      </el-date-picker>
      <div style="margin-right: 10px">
        <select-platform-channel :params="params"></select-platform-channel>
      </div>
      <el-select
        v-model="params.booksId"
        filterable
        remote
        clearable
        style="width: 150px; margin-right: 10px"
        reserve-keyword
        placeholder="书籍名称"
        :remote-method="getBook"
        @change="handleBookIdChange"
        @clear="handleBookClear"
      >
        <el-option
          v-for="item in bookList"
          :key="item.id"
          :label="item.bookName"
          :value="item.id"
        ></el-option>
      </el-select>
      <select-dept-user :params="params" />
      <el-button type="board" @click="handleSearch" style="margin-left: 10px"
        >搜索</el-button
      >
      <el-button type="info" @click="handleReset">重置</el-button>
    </div>
    <el-row :gutter="15">
      <el-col :span="7" class="left-part parts">
        <div class="today-put">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span class="card-title">今日投放</span>
            </div>
            <el-row :gutter="10" style="margin-top: 10px">
              <el-col
                :span="12"
                v-for="(item, index) in todayPutPart"
                :key="index"
              >
                <div class="put-item" @click="handleShowDrawer(item)">
                  <h4 :class="`put-${index + 1}`">{{ item.name }}</h4>
                  <div class="put-item-value">
                    <span class="total-value">{{ item.value }}</span>
                    <template v-if="!['广告', '计划'].includes(item.name)">
                      <template v-if="item.trend === 'up'">
                        <span class="change-value-red">
                          <i class="iconfont icon-shangsheng"></i>
                          <span>{{ item.trendVal }}</span>
                        </span>
                      </template>
                      <template v-else>
                        <span class="change-value-green">
                          <i class="iconfont icon-xiajiang"></i>
                          <span>{{ item.trendVal }}</span>
                        </span>
                      </template>
                    </template>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <div class="team-platform-consume-distributing">
            <el-row :gutter="2">
              <el-col :span="12">
                <div id="team-consume"></div>
              </el-col>
              <el-col :span="12">
                <div id="platform-consume"></div>
              </el-col>
            </el-row>
          </div>
          <div class="book-rate">
            <el-row :gutter="2">
              <el-col :span="12">
                <p
                  class="title"
                  :style="`color:${subTitleColor};font-size:${subTitleFontSize}`"
                >
                  书籍消耗分布
                </p>
                <div id="book-consume"></div>
              </el-col>

              <el-col
                :span="12"
                :style="`color:${subTitleColor};font-size:${subTitleFontSize}`"
              >
                <p class="title">投放转换率</p>
                <div id="delivery-conversion-rate"></div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
      <el-col :span="10" class="middle-part parts">
        <div class="today-recharge">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="cursor: pointer">
              <span class="card-title drawer-text" @click="showDuPont = true"
                >今日充值</span
              >
            </div>

            <el-row :gutter="10" style="margin-top: 10px">
              <div class="field-parts">
                <div
                  class="field-part"
                  v-for="(item, index) in fieldList"
                  :key="index"
                >
                  <p class="text-center">{{ item.cname }}</p>
                  <p
                    class="text-center"
                    :class="[
                      ['rechargeMounting', 'returnRate'].includes(item.name)
                        ? 'orange-color'
                        : '',
                    ]"
                  >
                    {{ item.value }}
                  </p>
                </div>
              </div>
              <el-row
                :gutter="5"
                type="flex"
                justify="center"
                style="margin-top: 10px"
              >
                <el-col :span="20">
                  <PercentageRuler
                    :percentage="getpercentage()"
                    :stroke-width="10"
                  />
                </el-col>
              </el-row>
            </el-row>
          </el-card>
        </div>

        <div class="today-recharge-bottom-part">
          <div class="title">D1回本不达标书籍</div>
          <div class="book-list">
            <template v-if="unAchievementBookList.length > 0">
              <div
                class="book-item line-overflow"
                v-for="(item, index) in unAchievementBookList.slice(0, 5)"
                :key="index"
              >
                {{ item }}
              </div>
              <div
                class="book-item check-more"
                v-if="unAchievementBookList.length > 5"
                @click="checkMore"
              >
                <el-popover placement="right" width="280" trigger="click">
                  <el-input
                    v-model="unBookName"
                    placeholder="请输入书籍名称"
                    style="margin-bottom: 10px"
                  ></el-input>
                  <div class="check-all">
                    <span
                      class="item"
                      v-for="(item, index) in copyUnAchievementBookList"
                      :key="index"
                      >{{ item }}</span
                    >
                  </div>
                  <a
                    class="region-item"
                    href="javascript:;"
                    slot="reference"
                    style="margin-left: 5px"
                    >查看全部</a
                  >
                </el-popover>
              </div>
            </template>
            <template v-else>
              <div style="font-weight: bold; font-size: 12px">暂无数据</div>
            </template>
          </div>
          <div class="days-payconsume-15">
            <div id="days-payconsume-15"></div>
          </div>
          <div class="days-payback-15">
            <div id="days-payback-15"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="7" class="right-part parts">
        <el-row :gutter="10">
          <div class="user-info">
            <el-row :gutter="10" style="flex" justify="space-between">
              <el-col :span="12">
                <div class="title">会员充值分布</div>
                <div id="vip-recharge"></div>
              </el-col>
              <el-col :span="12">
                <div class="title">客户类型充值分布</div>
                <div id="user-category"></div>
              </el-col>
            </el-row>
          </div>
          <div class="seven-days-contrast">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <div class="title">七日同时段对比图</div>
              <div class="choose-contrast-type">
                <div
                  class="type"
                  v-for="item in contrastType"
                  :key="item.id"
                  :class="{ isChoosed: curType === item.id }"
                  @click="getContrastType(item)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div id="seven-days-contrast-container"></div>
          </div>
        </el-row>
      </el-col>
    </el-row>
    <!-- 公共抽屉 -->
    <common-drawer
      :showDrawer="showCommonDrawer"
      @close="showCommonDrawer = false"
      :template="showTemplate"
      :nextParams="nextParams"
    />
    <!-- 杜邦图 -->
    <el-drawer
      class="duPont-drawer"
      title="小说投放概况(杜邦图分析)"
      :visible.sync="showDuPont"
      @close="showDuPont = false"
      size="100%"
      center
    >
      <DuPontPicture :show="showDuPont" :lastParams="params" />
    </el-drawer>
  </div>
</template>

<script>
import echarts from "echarts";
import SelectPlatformChannel from "@/components/SelectPlatformChannel.vue";
import DuPontPicture from "@/components/duPontPicture";
import SelectDeptUser from "@/components/SelectDeptUser";
import {
  formatThousandsnoz,
  formatThousandsOne,
  formatTime,
} from "@/assets/js/utils.js";
import PercentageRuler from "../../components/ruler";
import { disableTodayOptions } from "@/assets/js/options";
import { getBookList } from "@/api/account";
import {
  bookChannelVipUserCategory,
  getPutManagerManagerStatistics,
  operateManagerSevenDayData,
} from "@/api/monitor.js";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "putDecisionMaker",
  data() {
    return {
      params: {},
      nextParams: {},
      dataType: null,
      booksId: null,
      booksCost: null,
      vipRecharge: [],
      customerType: [],
      bookList: [],
      bookConsume: null, // 书籍消耗
      deliveryConversionRate: null, // 投放转换率
      vipName: "",
      vipList: [],
      todayPutPart: [],
      fieldList: [],
      dividingRules: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      teamConsumeData: null,
      pieTeamConsume: null, // 团队消耗可视图
      platformConsumeData: null,
      piePlatformConsume: null, // 平台消耗可视图
      pieVip: null, // 会员充值分布
      pieUserCategory: null, // 客户类型分布
      adminUserId: null,
      putConversionRateData: null, // 转换效率值
      areaGraphData: null,
      fifteenContrast: null,
      fifteenPayBack: null,
      conversionGraph: null,
      deptId: null,
      unAchievementBookList: [],
      copyUnAchievementBookList: [], // 用于静态筛选
      sevenData: null,
      sevenDataGraph: null,
      sevenDataGraphOption: null,
      curType: 1, // *默认为消耗
      changeSqSizeNum: 0, // 通过resize事件 重新渲染书籍消耗分布的次数
      subTitleColor: "#969696",
      subTitleFontSize: "12px",
      showTemplate: null,
      showCommonDrawer: false,
      unBookName: "", // 未达标书籍名称
      putName: "",
      contrastType: [
        {
          name: "消耗",
          id: 1,
        },
        {
          name: "充值",
          id: 2,
        },
        {
          name: "新用户",
          id: 3,
        },
        {
          name: "老用户",
          id: 4,
        },
      ],
      // 杜邦图
      showDuPont: false,
      disableTodayOptions,
    };
  },
  computed: {
    ...mapGetters(["userList"]),
  },
  async mounted() {
    await this.initData("created");
    await this.initEchartEvent();
    window.onresize = (e) => {
      this.handleResize(e);
      this.piePlatformConsume.resize();
      this.pieTeamConsume.resize();
      this.bookConsume.resize();
      this.deliveryConversionRate.resize();
      this.fifteenContrast.resize();
      this.fifteenPayBack.resize();
      this.pieVip.resize();
      this.pieUserCategory.resize();
      this.sevenDataGraph.resize();
    };
    this.handleResize();

    this.$on("hook:beforeDestroy", () => {
      window.onresize = null;
    });
  },
  watch: {
    unBookName: {
      handler(newV) {
        this.copyUnAchievementBookList = newV
          ? this.unAchievementBookList.filter((item) => item.includes(newV))
          : this.unAchievementBookList;
      },
    },
  },
  methods: {
    async initData(flag = "update") {
      await this.getData(flag);
      await this.initPieGraph();
      // *初始化书籍消耗分布
      this.initSqGraph();
      // *初始化投放转换率漏斗图
      this.initFunnelGraph();
      // *初始化折线图
      this.initFifteenPayBack();
      this.initFifteenContrast();
      // * 初始化饼图
      this.initPieGraphPartOne();
      // *初始化柱状图
      this.initCategoryGraph();
    },
    initEchartEvent() {
      this.sevenDataGraph.getZr().on("click", (params) => {
        if (params.target && params.target.name) {
          this.handleShowDrawer({ eName: "consumeCharging" }, "hour");
        }
      });
      this.piePlatformConsume.on("click", "series", (params) => {
        this.params.originPlatform = params.data.platformId;
        this.handleSearch();
      });
    },
    handleResize(e) {
      const { offsetWidth } = document.documentElement;

      this.$nextTick(() => {
        offsetWidth <= 1400
          ? this.initSqGraph(true, true)
          : this.initSqGraph(false, true);
      });
    },
    initPieGraph() {
      const colors = [
        {
          c1: "#81b6fa",
          c2: "#81b6fa",
        },
        {
          c1: "#AB74E9",
          c2: "#AB74E9",
        },
        {
          c1: "#3F80FF",
          c2: "#3F80FF",
        },
        {
          c1: "#FF9163",
          c2: "#FF9163",
        },
        {
          c1: "#a1c4fd",
          c2: "#c2e9fb",
        },
        {
          c1: "#FB817F",
          c2: "#FB817F",
        },
        {
          c1: "#ccc",
          c2: "#ccc",
        },
        {
          c1: "#f7f7f7",
          c2: "#f7f7f7",
        },
      ];
      const option = {
        title: {
          text: "团队消耗分布",
          top: "10%",
          textStyle: {
            fontSize: 12,
            color: "#969696",
          },
        },
        tooltip: {
          formatter: "{b}:{c}万<br/>{d}%",
        },
        legend: {
          top: "bottom",
          show: false,
        },

        series: [
          {
            // name: "面积模式",
            type: "pie",
            radius: ["10%", "60%"],
            center: ["50%", "50%"],
            roseType: "area",
            emphasis: {
              label: {
                show: true,
                fontWeight: "bold",
                fontSize: "12",
              },
            },
            label: {
              fontSize: 10,
              color: "#969696",
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
              formatter: (params) => {
                return `${params.name}\n${params.percent}%`;
              },
            },
            labelLine: {
              // normal: {
              show: true,
              showAbove: true,
              length2: 5,
              length: -20,
              // }
            },
            itemStyle: {
              color: (params) => {
                const colorList = [];
                const len = this.teamConsumeData.length;
                for (let i = 0; i < Math.ceil(len / colors.length); i++) {
                  colorList.push(...colors);
                }
                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: colorList[params.dataIndex].c1,
                  },
                  {
                    offset: 1,
                    color: colorList[params.dataIndex].c2,
                  },
                ]);
              },
            },
            data: this.teamConsumeData,
          },
        ],
      };
      const option2 = {
        title: {
          text: "平台消耗分布",
          top: "10%",
          textStyle: {
            fontSize: 12,
            color: "#969696",
          },
        },
        legend: {
          top: "bottom",
          show: false,
        },
        grid: {
          left: "left",
          right: "4%",
        },
        tooltip: {
          formatter: "{b}:{c}万<br/>{d}%",
        },
        series: [
          {
            // name: "面积模式",
            type: "pie",
            radius: ["10%", "60%"],
            center: ["50%", "50%"],
            roseType: "area",
            emphasis: {
              label: {
                show: true,
                fontWeight: "bold",
                fontSize: "12",
              },
            },
            label: {
              fontSize: 10,
              color: "#969696",
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
              formatter: (params) => {
                return `${params.name}\n${params.percent}%`;
              },
            },
            labelLine: {
              show: true,
              showAbove: true,
              length2: 5,
              length: -20,
            },
            itemStyle: {
              color: (params) => {
                const colorList = [];
                const len = this.platformConsumeData.length;
                for (let i = 0; i < Math.ceil(len / colors.length); i++) {
                  colorList.push(...colors);
                }

                return new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: colorList[params.dataIndex].c1,
                  },
                  {
                    offset: 1,
                    color: colorList[params.dataIndex].c2,
                  },
                ]);
              },
            },
            data: this.platformConsumeData,
          },
        ],
      };
      if (!this.pieTeamConsume) {
        this.pieTeamConsume = echarts.init(
          document.getElementById("team-consume")
        );
      }

      // this.pieTeamConsume.clear();
      this.pieTeamConsume.setOption(option);
      if (!this.piePlatformConsume) {
        this.piePlatformConsume = echarts.init(
          document.getElementById("platform-consume")
        );
      }
      // this.piePlatformConsume.clear();
      this.piePlatformConsume.setOption(option2);
    },
    initSqGraph(showLess = false, isresize = true) {
      const len = this.booksCost.length;
      const value = this.deepClone(this.booksCost)
        .map((item) => {
          return {
            value: Number(item.cashCostAmt),
            name: item.bookName,
            booksId: item.booksId,
          };
        })
        .sort((a, b) => a.value - b.value)
        .reverse();

      const data = this.deepClone(value);
      const colors = [];
      const customColors = [
        "#81b6fa",
        "#6273E0",
        "#3F80FF",
        "#AB74E9",
        "#FF9163",
        "#FFB85E",
        "#00BF8A",
        "#FB817F",
      ];
      for (let i = 0; i < Math.ceil(len / customColors.length); i++) {
        colors.push(...customColors);
      }
      const options = {
        tooltip: {
          formatter: (info) => {
            return `<div class='tooltip-title'><p>书名:${info.name}</p>
            <p>消耗:${info.value}万</p>
            </div>`;
          },
        },
        color: colors,
        legend: {
          show: true,
        },
        series: [
          {
            left: "left",
            width: "100%",
            breadcrumb: {
              show: false,
            },
            top: "10%",
            bottom: "0",
            roam: false,
            nodeClick: false,
            type: "treemap",
            label: {
              show: true,
              formatter: "{b}",
            },
            data,
          },
        ],
      };
      if (this.bookConsume) {
        this.bookConsume.dispose();
      }
      this.bookConsume = echarts.init(document.getElementById("book-consume"));
      this.bookConsume.setOption(options);
      this.bookConsume.on("click", "series", (info) => {
        this.params.booksId = info.data.booksId;
        this.getBookById(this.params.booksId);
        this.handleSearch();
      });
    },
    initFunnelGraph() {
      const options = {
        tooltip: {
          trigger: "item",
          formatter: (info) => {
            return `<div>${info.name}:${formatThousandsnoz(
              info.data.trueValue
            )}<br/>${
              info.data.trueValue !== 0
                ? (info.data.truePercent * 100).toFixed(2)
                : "0"
            }%</div>`;
          },
        },
        color: ["#6998f7", "#3F80FF", "#8aaff8", "#b8cffc", "#ccdaf7"],
        series: [
          {
            type: "funnel",
            left: "left",
            top: "10%",
            bottom: "0",
            width: "90%",
            minSize: "0%",
            maxSize: "100%",
            sort: "descending",
            gap: 0,
            label: {
              show: true,
              position: "inside",
              formatter: (info) => {
                return `${info.name}\n\n${formatThousandsnoz(
                  info.data.trueValue
                )}  ${
                  info.data.trueValue !== 0
                    ? (info.data.truePercent * 100).toFixed(2)
                    : "0"
                }%`;
              },
            },
            data: this.putConversionRateData,
          },
        ],
      };
      if (!this.deliveryConversionRate) {
        this.deliveryConversionRate = echarts.init(
          document.getElementById("delivery-conversion-rate")
        );
      }
      this.deliveryConversionRate.setOption(options);
    },
    initFifteenContrast() {
      const option = {
        title: {
          text: "近15日消耗vsD1回本面积图",
          textStyle: {
            fontSize: 12,
            color: "#A7A7A7",
          },
        },
        legend: {
          icon: "circle",
          data: ["现金消耗", "回本金额"],
          right: "10%",
          textStyle: {
            color: this.subTitleColor,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a0}: {c0}万<br />{a1}: {c1}万",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
          data: this.areaGraphData.date.map((item) => item.slice(5)),
        },
        yAxis: {
          type: "value",
          show: false,
        },
        grid: {
          left: "left",
          right: "6%",
          bottom: "5%",
          containLabel: true,
        },
        series: [
          {
            data: this.areaGraphData.data[0].value,
            name: "现金消耗",
            type: "line",
            areaStyle: {},
            lineStyle: {
              color: "#579BF4",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#579BF4",
                  },
                  {
                    offset: 1,
                    color: "#fcf4f4",
                  },
                ],
              },
            },
            symbol: "none",
          },
          {
            data: this.areaGraphData.data[1].value,
            name: "回本金额",
            type: "line",
            areaStyle: {},
            symbol: "none",
            lineStyle: {
              color: "#FFB85E",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#FFB85E",
                  },
                  {
                    offset: 1,
                    color: "#fcf4f4",
                  },
                ],
              },
            },
          },
        ],
      };
      if (!this.fifteenContrast) {
        this.fifteenContrast = echarts.init(
          document.getElementById("days-payconsume-15")
        );
      }
      this.fifteenContrast.setOption(option);
    },
    initFifteenPayBack() {
      const option = {
        title: {
          text: "近15日D1回本率",
          textStyle: {
            fontSize: 12,
            color: "#969696",
          },
        },
        color: ["#579BF4", "#FFB85E"],
        legend: {
          icon: "circle",
          data: ["D1回本率", "平台30日均线"],
          right: "10%",
          textStyle: {
            color: this.subTitleColor,
            fontWeight: "bold",
          },
        },
        tooltip: {
          trigger: "axis",
          formatter: "{a0}: {c0}%<br />{a1}: {c1}%",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "left",
          right: "4%",
          bottom: "0%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 8,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
          data: this.conversionGraph.date.map((item) => item.slice(5)),
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            name: "D1回本率",
            data: this.conversionGraph.data[0].value,
            type: "line",
            smooth: true,
            lineStyle: {
              color: "#579BF4",
            },
            symbol: "none",
          },
          {
            name: "平台30日均线",
            data: this.conversionGraph.data[1].value,
            type: "line",

            lineStyle: {
              color: "#FFB85E",
            },
            symbol: "none",
          },
        ],
      };
      if (!this.fifteenPayBack) {
        this.fifteenPayBack = echarts.init(
          document.getElementById("days-payback-15")
        );
      }
      this.fifteenPayBack.setOption(option);
    },
    initPieGraphPartOne() {
      const option = {
        tooltip: {
          formatter: "{b}:{c}万<br/>{d}%",
        },
        color: ["#579BF4", "#FFB85E"],
        series: [
          {
            type: "pie",
            radius: ["40%", "55%"],
            avoidLabelOverlap: false,
            right: "0",
            emphasis: {
              label: {
                show: true,
                fontWeight: "bold",
                fontSize: "12",
              },
            },
            label: {
              normal: {
                show: true,
                formatter: "{b}\n{c}万",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: "5",
                length2: "17",
              },
            },
            data: this.vipRecharge,
          },
        ],
      };
      const optionCategory = JSON.parse(JSON.stringify(option));
      optionCategory.color = ["#FFB85E", "#579BF4"];
      optionCategory.series[0].data = this.customerType;
      if (!this.pieVip) {
        this.pieVip = echarts.init(document.getElementById("vip-recharge"));
      }
      this.pieVip.setOption(option);
      if (!this.pieUserCategory) {
        this.pieUserCategory = echarts.init(
          document.getElementById("user-category")
        );
      }
      this.pieUserCategory.setOption(optionCategory);
    },
    initCategoryGraph() {
      this.sevenDataGraphOption = {
        grid: {
          left: "left",
          right: "5%",
          width: "100%",
        },
        color: ["#579BF4"],
        tooltip: {
          trigger: "axis",
          formatter: "{c}万<br/>{b}",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: this.sevenData.x,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#8492A6", //更改坐标轴颜色
            },
          },
          axisLabel: {
            fontSize: 8,
          },
        },
        yAxis: {
          type: "value",
          show: false,
        },
        series: [
          {
            type: "bar",
            data: this.sevenData.y,
            label: {
              show: true,
              position: "insideTop",
              formatter: (info) => {
                return `${info.value}万`;
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
              },
            },

            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      };
      if (!this.sevenDataGraph) {
        this.sevenDataGraph = echarts.init(
          document.getElementById("seven-days-contrast-container")
        );
      }
      this.sevenDataGraph.setOption(this.sevenDataGraphOption);
    },
    getTargetLineStyle() {
      return {
        width: `${
          32.5 *
          (parseFloat(this.fieldList[this.fieldList.length - 1].value) / 100)
        }vw`,
      };
    },
    async getData(flag) {
      if (flag === "created") {
        // 缓存获取
        const tmpIndex = this.$store.state.routerHistory.findIndex(
          (item) => item.path === this.$route.path
        );
        // 若存在缓存
        if (tmpIndex !== -1) {
          this.params = this.$store.state.routerHistory[tmpIndex].params;
          // 若无查询时间 添加默认查询时间
          if (!this.params.queryDate) {
            this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
          }
          this.getBookById(this.params.booksId);
        } else {
          // 不存在缓存 初始化筛选条件
          this.params = {
            queryDate: formatTime(new Date(), "yyyy-MM-dd"),
          };
        }
      }
      const res = await getPutManagerManagerStatistics({
        originPlatform:
          this.params.originPlatform !== null
            ? this.params.originPlatform
            : null,
        accountChannelId:
          this.params.accountChannelId !== null
            ? this.params.accountChannelId
            : null,
        booksId: this.params.booksId,
        adminUserId:
          this.params.adminUserId !== null ? this.params.adminUserId : null,
        deptId: this.params.deptId !== null ? this.params.deptId : null,
        queryDate: this.params.queryDate,
      });
      const {
        bookCost, // 书籍消耗分布
        customerType, //	客户类型充值分布 单位：万
        vipRecharge, //	会员充值分布 单位：万
        data,
        floorBooks, // D1回本不达标书籍
        teamCost, // 团队消耗分布 单位：万
        conversionGraph, // 近15日D1回本率趋势
        platformCost, // 平台消耗分布
        putConversionRate,
        areaGraph,
      } = res;

      const {
        cashCostAmt,
        cashCostAmtMounting,
        channelNum,
        channelNumMounting,
        booksNum,
        booksNumMounting,
        putNum,
        putNumMounting,
        adNum,
        campaignNum,
        newUser, // *增粉
        newUserPrice, // *单粉价
        todayRechargeAmt, //* 今日充值
        returnRate, // *回本率
        rechargeMounting, // * 环比
        firstAmt, // * D1回本
        orderCnt, // *(订)单量
        payNum, // * 付费人数
      } = data;

      this.fieldList = [
        {
          name: "cashCostAmt",
          cname: "现金投放",
          value: `${cashCostAmt}万`,
        },
        {
          name: "newUser",
          cname: "增粉",
          value: `${newUser}`,
        },
        {
          name: "newUserPrice",
          cname: "单粉价",
          value: newUserPrice,
        },
        {
          name: "todayRechargeAmt",
          cname: "今日充值",
          value: `${todayRechargeAmt}万`,
        },
        {
          name: "rechargeMounting",
          cname: "环比",
          value: `${rechargeMounting}%`,
        },
        {
          name: "firstAmt",
          cname: "D1回本",
          value: `${firstAmt}万`,
        },
        {
          name: "orderCnt",
          cname: "单量",
          value: orderCnt,
        },
        {
          name: "payNum",
          cname: "付费人数",
          value: payNum,
        },
        {
          name: "returnRate",
          cname: "回本率",
          value: `${returnRate}%`,
        },
      ];

      this.todayPutPart = [
        {
          eName: "cashCost",
          name: "现金投放",
          value: `${formatThousandsOne(cashCostAmt)}万`,
          trend: Number(cashCostAmtMounting) >= 0 ? "up" : "down",
          trendVal: `${cashCostAmtMounting}%`,
        },
        {
          eName: "channelNum",
          name: "公众号",
          value: `${channelNum}`,
          trend: Number(channelNumMounting) >= 0 ? "up" : "down",
          trendVal: channelNumMounting,
        },
        {
          eName: "booksNum",
          name: "书籍",
          value: `${booksNum}`,
          trend: Number(booksNumMounting) >= 0 ? "up" : "down",
          trendVal: booksNumMounting,
        },
        {
          eName: "putsNum",
          name: "投手",
          value: `${putNum}`,
          trend: Number(putNumMounting) >= 0 ? "up" : "down",
          trendVal: Number(putNumMounting),
        },
        {
          eName: "adNum",
          name: "广告",
          value: `${adNum}`,
        },
        {
          eName: "campaignNum",
          name: "计划",
          value: `${campaignNum}`,
        },
      ];

      this.unAchievementBookList = [...this.deepClone(floorBooks)];
      this.copyUnAchievementBookList = JSON.parse(
        JSON.stringify(this.unAchievementBookList)
      );
      this.areaGraphData = this.deepClone(areaGraph);

      this.conversionGraph = this.deepClone(conversionGraph);

      this.teamConsumeData = this.deepClone(teamCost).map((item) => {
        return {
          value: item.cashCostAmt,
          name: item.teamName,
        };
      });
      this.platformConsumeData = this.deepClone(platformCost).map((item) => {
        return {
          value: item.cashCostAmt,
          platformId: item.platformId,
          name: item.platformName,
        };
      });
      const putConversion = [
        {
          name: "clkPv",
          cname: "点击",
        },
        {
          name: "expPv",
          cname: "曝光",
        },
        {
          name: "newUser",
          cname: "增粉",
        },
        {
          name: "payNum",
          cname: "付费",
        },
      ];
      let putConversionRateData = Object.keys(putConversionRate).map(
        (propertyName) => {
          const target = putConversion.find((key) => key.name === propertyName);
          return {
            name: target.cname,
            trueValue: Number(
              putConversionRate[target.name].split(",").join("")
            ),
          };
        }
      );

      let maxValue = Math.max(
        ...putConversionRateData.map((item) => Number(item.trueValue))
      );
      let maxIndex = putConversionRateData.findIndex(
        (item) => Number(item.trueValue) === maxValue
      );
      let copyPutCoversionRateData = this.deepClone(putConversionRateData);
      putConversionRateData.forEach((item) => {
        if (item.name === "曝光") {
          item.value = item.trueValue;
        }
        if (item.name === "点击") {
          item.value = maxValue * 0.8;
        }
        if (item.name === "增粉") {
          item.value = maxValue * 0.6;
        }
        if (item.name === "付费") {
          item.value = maxValue * 0.4;
        }
        item.truePercent = (item.trueValue / maxValue).toFixed(4);
      });

      this.putConversionRateData = putConversionRateData;

      this.booksCost = this.deepClone(bookCost);

      this.vipRecharge = [];
      this.customerType = [];
      Object.entries(this.deepClone(vipRecharge)).forEach(([key, value]) => {
        if (key === "noVipRechargeAmt") {
          this.vipRecharge.push({
            value,
            name: "非会员",
          });
        } else {
          this.vipRecharge.push({
            value,
            name: "会员",
          });
        }
      });
      Object.entries(this.deepClone(customerType)).forEach(([key, value]) => {
        if (key === "newCustomer") {
          this.customerType.push({
            value,
            name: "新客户",
          });
        } else {
          this.customerType.push({
            value,
            name: "老客户",
          });
        }
      });
      this.sevenData = await operateManagerSevenDayData({
        originPlatform:
          this.params.originPlatform !== null
            ? this.params.originPlatform
            : null,
        accountChannelId:
          this.params.accountChannelId !== null
            ? this.params.accountChannelId
            : null,
        booksId: this.params.booksId,
        adminUserId: this.params.adminUserId,
        deptId: this.params.deptId,
        dataType: this.curType,
        queryDate: this.params.queryDate,
      });
    },
    handleShowDrawer(item, timeTpe = "date") {
      // ;
      if (["cashCost", "channelNum"].includes(item.eName)) {
        this.showTemplate = "channelConsume";
      }
      if (item.eName === "booksNum") {
        this.showTemplate = "bookConsume";
      }
      if (item.eName === "adNum") {
        this.showTemplate = "adConsume";
      }
      if (item.eName === "putsNum") {
        this.showTemplate = "userConsume";
      }
      if (item.eName === "campaignNum") {
        this.showTemplate = "putProject";
      }
      if (item.eName === "consumeCharging") {
        this.showTemplate = "consumeCharging";
      }

      // let tmpQuery =
      //   timeTpe === "date"
      //     ? formatTime(new Date(), "yyyy-MM-dd")
      //     : formatTime(new Date(), "yyyy-MM-dd 23:59:59");
      let tmpQuery =
        timeTpe === "date"
          ? this.params.queryDate
          : this.params.queryDate + " 23:59:59";
      if (this.showTemplate !== "putProject") {
        this.nextParams = {
          ...this.params,
          queryDate: tmpQuery,
        };
      } else {
        this.nextParams = {
          putData: {
            ...this.params,
            selectTime: [tmpQuery, tmpQuery],
            bookId: this.params.booksId,
          },
        };
      }
      this.showCommonDrawer = true;
    },
    handleSearch() {
      this.addRouterHistory({
        path: this.$route.path,
        params: {
          booksId: this.params.booksId,
          originPlatform: this.params.originPlatform,
          accountChannelId: this.params.accountChannelId,
          adminUserId: this.params.adminUserId,
          deptId: this.params.deptId,
        },
      });
      this.initData();
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    checkMore() {},
    async getContrastType(val) {
      this.curType = val.id;
      this.sevenData = await operateManagerSevenDayData({
        originPlatform:
          this.params.originPlatform !== null
            ? this.params.originPlatform
            : null,
        accountChannelId:
          this.params.accountChannelId !== null
            ? this.params.accountChannelId
            : null,
        booksId: this.params.booksId,
        adminUserId: this.params.adminUserId,
        deptId: this.params.deptId !== null ? this.params.deptId : null,
        dataType: this.curType,
      });

      this.sevenDataGraphOption.xAxis.data = this.sevenData.x;
      this.sevenDataGraphOption.series[0].data = this.sevenData.y;
      this.sevenDataGraph.setOption(this.sevenDataGraphOption);
    },
    getpercentage() {
      let value = this.fieldList.find((item) => item.name === "returnRate");

      return value ? parseInt(value.value) : 0;
    },
    handleBookIdChange() {},
    handleBookClear() {},
    handleReset() {
      // this.params = ;
      this.params.booksId = null;
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.adminUserId = null;
      this.params.deptId = null;
      this.booksId = null;
      this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
      this.initData();
    },
    deepClone(val) {
      return JSON.parse(JSON.stringify(val));
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
  },
  components: {
    SelectPlatformChannel,
    PercentageRuler,
    SelectDeptUser,
    DuPontPicture,
  },
};
</script>

<style lang="scss" scoped>
a {
  color: #459ae9;
}
.text-center {
  text-align: center;
}
.orange-color {
  color: #ffa700 !important;
}
.el-icon-bottom {
  color: #008000;
}
.el-icon-top {
  color: #d33034;
}
.isChoosed {
  color: #579bf4 !important;
}

.field-parts {
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
  .field-part {
    > p:nth-of-type(1) {
      margin-bottom: 1.5vh;
      font-size: 12px;
      color: #969696;
    }
    > p:nth-of-type(2) {
      font-weight: bold;
      // color: #51acff;
      font-size: 14px;
    }
  }
  .field-part-payback {
    margin-right: 2vw;
    > p:nth-of-type(2) {
      color: #00ccff;
    }
  }
}

.put-decision-maker {
  background: #fff;
  .putter-operation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .left-part {
    .today-put-container {
      .today-put-item {
        width: 50%;
        display: inline-block;
        margin-bottom: 1vw;
        .top-put-item-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 13px;
          .tag {
            height: 10px;
            width: 2px;
            border-radius: 1px;
            background: red;
            margin-right: 10px;
          }
        }
        .bottom-put-item-info {
          margin-top: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .data-value {
            margin-left: 12px;
            font-size: 14px;
          }
        }
      }
    }
    .team-platform-consume-distributing {
      .title {
        font-weight: bold;
      }
      #team-consume {
        height: 250px;
      }
      #platform-consume {
        height: 250px;
      }
    }
    .book-rate {
      .title {
        font-weight: bold;
      }
      #book-consume {
        // width: 50%;
        height: 250px;
      }
      #delivery-conversion-rate {
        // margin-left: 1vw;
        // flex-grow: 1;
        height: 250px;
      }
    }
  }
  .middle-part {
    .payback-ration-line {
      margin-top: 2vw;
      margin-bottom: 1vw;
      .dividing-ruler {
        display: flex;
        width: 32vw;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        .num {
          position: relative;
          .num-info {
            font-size: 0.8em;
            color: #ccc;
            position: absolute;
            top: -1vw;
            left: -0.8vw;

            // left: -0.5vw;
          }
          .divide-line {
            height: 4px;
            width: 1px;
            background: #ccc;
            margin: 2px 0;
          }
        }
      }
      .line {
        border-radius: 8px;
        height: 0.5vw;
        width: 32vw;
        margin: 0 auto;
        background: #bcbcbc;
        position: relative;
        .target-line {
          position: absolute;
          height: 0.5vw;
          left: 0;
          top: 0;
          background: #2094ff;
          border-radius: 8px;
        }
      }
    }
    .today-recharge-bottom-part {
      margin-top: 2vw;
      .title {
        color: #969696;
        // font-size: 0.7em;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        margin: 0;
      }
      .book-list {
        margin-top: 1vw;
        margin-left: 1vw;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        .book-item {
          margin-right: 0.8vw;
          margin-bottom: 0.5vw;
          max-width: 90px;
          color: red;
          font-size: 0.7em;
        }
        .check-more {
          right: 0;
          font-size: 12px;
          color: #969696;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    .days-payconsume-15 {
      margin-top: 30px;
      position: relative;
      #days-payconsume-15 {
        height: 250px;
      }
    }
    .days-payback-15 {
      margin-top: 70px;
      #days-payback-15 {
        width: 100%;
        height: 150px;
      }
    }
  }
  .right-part {
    .user-info {
      margin-top: 51px;
      font-size: 12px;
      color: #969696;
      .title {
        font-weight: bold;
      }
      #vip-recharge {
        // width: 17vw;
        height: 300px;
      }
      #user-category {
        // width: 17vw;
        height: 300px;
      }
    }
    .seven-days-contrast {
      // margin-top:3.7vw;
      margin-top: 20px;
      .title {
        font-size: 12px;
        font-weight: bold;
        color: #969696;
        text-align: left;
      }
      .choose-contrast-type {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .type {
          font-size: 0.8em;
          font-weight: bold;
          color: #969696;
          margin-right: 0.5vw;
          cursor: pointer;
        }
      }
      #seven-days-contrast-container {
        height: 380px;
        width: 100%;
      }
    }
  }
}

.check-all {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 150px;
  overflow: auto;
  margin-left: 5px;
  span {
    margin-bottom: 3px;
  }
}

.el-card {
  border: none;
  /deep/ .el-card__header {
    padding: 12px 0;
  }
  /deep/ .el-card__body {
    padding: 0;
  }
}

.el-card .card-title {
  display: inline-block;
  color: #969696;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}

.put-item {
  padding: 4px 0;
  cursor: pointer;
  h4 {
    height: 14px;
    line-height: 14px;
    color: #475669;
    font-size: 14px;
    font-weight: 400;
    padding-left: 7px;
    border-radius: 2px;
  }
  .put-1 {
    border-left: 4px solid #3f80ff;
  }
  .put-2 {
    border-left: 4px solid #22c7b1;
  }
  .put-3 {
    border-left: 4px solid #ffb85e;
  }
  .put-4 {
    border-left: 4px solid #6273e0;
  }
  .put-5 {
    border-left: 4px solid #b47eed;
  }
  .put-6 {
    border-left: 4px solid #fc8c8a;
  }
  .put-item-value {
    padding: 5px 0 5px 10px;
    height: 34px;
    // padding-right:0;
    .icon-xiajiang {
      font-size: 12px;
    }
  }
  .total-value {
    font-size: 24px;
    font-weight: bold;
    color: #1f2d3d;
  }
  .change-value-red {
    margin-left: auto;
    font-size: 12px;
    color: #fd5d5a;
  }
  .change-value-green {
    margin-left: auto;
    font-size: 12px;
    color: #22c7b1;
  }
}
</style>

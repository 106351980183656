<template>
  <div>
    <!-- 搜索条件 -->
    <div class="filter-box" style="padding: 0 80px">
      <div class="row">
        <select-platform-channel :params="params" />
        <el-select
          v-model="params.booksId"
          filterable
          remote
          clearable
          style="width: 150px"
          reserve-keyword
          placeholder="书籍名称"
          :remote-method="getBook"
        >
          <el-option
            v-for="item in bookList"
            :key="item.id"
            :label="item.bookName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button type="board" @click="handlePageChange"> 搜索 </el-button>
        <el-button type="info" @click="handleReset"> 重置 </el-button>
      </div>
    </div>
    <div class="duPont-box">
      <canvas id="duPont" width="1350" height="700"></canvas>
    </div>
  </div>
</template>

<script>
import { operateDuPont } from "@/api/monitor";
import { getBookList } from "@/api/account";
import SelectPlatformChannel from "@/components/SelectPlatformChannel";
export default {
  props: {
    lastParams: Object,
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show: function (newvalue) {
      if (newvalue) {
        this.params = {
          ...this.lastParams,
        };
        this.handlePageChange();
      }
    },
  },
  components: {
    SelectPlatformChannel,
  },
  data() {
    return {
      params: {},
      bookList: [],
      tmpshow: false,
      // 左侧数据
      putContent: [],
      contentData: [
        {
          title: "投放金额",
          Left: "1000",
          Right: "-20%",
        },
        {
          title: "投放金额",
          Left: "1000",
          Right: "-20%",
        },
        {
          title: "投放金额",
          Left: "1000",
          Right: "-20%",
        },
        {
          title: "投放金额",
          Left: "1000",
          Right: "-20%",
        },
        {
          title: "投放金额",
          Left: "1000",
          Right: "-20%",
        },
      ],
      customerService: [],
      nowFans: [],
      // 连接数据
      newUserRecharge: [],
      oldUserRecharge: [],
      newFans: [],
      // 中间部分
      todyrecharge: [],

      // 右侧数据
      vipRecharge: [],
      registerRecharge: [],
      // 连接数据
      newUserRechargeMony: [],
      oldUserRechargeMony: [],
    };
  },
  methods: {
    // 构造左侧数据
    makeItem(
      titleColor = "#22C7B1",
      contentData,
      x,
      y,
      direction,
      totalValue,
      leftTotalText,
      leftTotalValue,
      rightTotalText,
      rightTotalValue
    ) {
      for (let i = 0; i < contentData.length; i++) {
        var canvas = document.getElementById("duPont");
        var ctx = canvas.getContext("2d");
        var boxTitleWidth = 150;
        var boxTitleHeight = 24;
        var boxContentHeight = 34;
        var itemInterval = 20;
        var lineTextColor = "#8492A6";
        var offsiteX = x;
        var offsiteY =
          y + i * (boxTitleHeight + boxContentHeight + itemInterval);
        var boxTitleColor = titleColor;
        var boxTitleFont = "14px sans-serif";
        var boxTitleFontColor = "#fff";
        var boxContentFont = "12px sans-serif";
        var boxContentColor = "#E9F0F7";
        var boxContentLeftColor = "#1F2D3D";
        var boxContentRightColorR = "#FD5D5A";
        var boxContentRightColorG = "#22C7B1";
        var lineColor = "#516174";
        var lineWidth = 50;
        var fontTitlePosition = {
          x: offsiteX + boxTitleWidth / 2,
          x: offsiteY + boxTitleHeight / 2,
        };
        // 数据
        var title = contentData[i].title;
        var contentLeft = contentData[i].Left;
        var contentRight = contentData[i].Right;
        ctx.fillStyle = boxTitleColor;
        //绘制矩形
        ctx.fillRect(offsiteX, offsiteY, boxTitleWidth, boxTitleHeight);
        ctx.fillStyle = boxContentColor;
        ctx.fillRect(
          offsiteX,
          offsiteY + boxTitleHeight,
          boxTitleWidth,
          boxContentHeight
        );
        ctx.strokeStyle = lineColor;
        ctx.beginPath();
        // 左侧数据
        if (direction === "left") {
          //绘制横线
          ctx.moveTo(offsiteX + boxTitleWidth, offsiteY + boxTitleHeight); //把画笔移动到指定的坐标
          ctx.lineTo(
            offsiteX + boxTitleWidth + lineWidth,
            offsiteY + boxTitleHeight
          ); //绘制一条从当前位置到指定坐标200, 50的直线.
          if (i < contentData.length - 1) {
            ctx.lineTo(
              offsiteX + boxTitleWidth + lineWidth,
              offsiteY + boxTitleHeight * 2 + boxContentHeight + itemInterval
            );
          }
          ctx.stroke(); //绘制路径。
          // 右侧数据 需要百分占比
        } else if (direction === "right") {
          //绘制横线
          ctx.moveTo(offsiteX, offsiteY + boxTitleHeight); //把画笔移动到指定的坐标
          ctx.lineTo(offsiteX - lineWidth, offsiteY + boxTitleHeight); //绘制一条从当前位置到指定坐标200, 50的直线.
          if (i < contentData.length - 1) {
            ctx.lineTo(
              offsiteX - lineWidth,
              offsiteY + boxTitleHeight * 2 + boxContentHeight + itemInterval
            );
          }
          ctx.stroke(); //绘制路径。
          // 绘制百分占比
          ctx.fillStyle = lineTextColor;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          if (i === 0) {
            ctx.fillText(leftTotalText, offsiteX - lineWidth / 2, offsiteY);
          }
          ctx.fillText(
            // (
            //   contentData[i].Left.split(",").join("") /
            //   leftTotalValue.split(",").join("")
            // ).toFixed(2) *
            //   100 +
            //   "%",
            this.formateNumber(contentData[i].Left, leftTotalValue),
            offsiteX - lineWidth / 2,
            offsiteY + boxTitleHeight / 2 + 2
          );
          // 中间--链接数据--
        } else if (direction === "middle") {
          //绘制横线
          ctx.strokeStyle = lineColor;
          ctx.moveTo(offsiteX, offsiteY + boxTitleHeight); //把画笔移动到指定的坐标
          ctx.lineTo(offsiteX - lineWidth, offsiteY + boxTitleHeight); //绘制左侧线条
          ctx.moveTo(offsiteX + boxTitleWidth, offsiteY + boxTitleHeight); //把画笔移动到指定的坐标
          ctx.lineTo(
            offsiteX + boxTitleWidth + lineWidth,
            offsiteY + boxTitleHeight
          ); //绘制右侧线条
          ctx.stroke(); //绘制路径。
          // 绘制左侧数据
          if (leftTotalText) {
            ctx.fillStyle = lineTextColor;
            ctx.font = boxTitleFontColor;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(leftTotalText, offsiteX - lineWidth / 2, offsiteY);
            ctx.fillText(
              leftTotalValue,
              offsiteX - lineWidth / 2,
              offsiteY + boxTitleHeight / 2 + 2
            );
          }
          // 绘制右侧数据
          if (rightTotalText) {
            ctx.fillStyle = lineTextColor;
            ctx.font = boxTitleFontColor;
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
              rightTotalText,
              offsiteX + boxTitleWidth + lineWidth / 2,
              offsiteY
            );
            ctx.fillText(
              rightTotalValue,
              offsiteX + boxTitleWidth + lineWidth / 2,
              offsiteY + boxTitleHeight / 2 + 2
            );
          }
        }
        // 绘制标题
        // 判断是否是黄色块数据
        if (titleColor === "#FED65A") {
          ctx.fillStyle = "#743E00";
        } else {
          ctx.fillStyle = boxTitleFontColor;
        }

        ctx.font = boxTitleFont;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          title,
          offsiteX + boxTitleWidth / 2,
          offsiteY + boxTitleHeight / 2
        );
        // 绘制内容左
        ctx.fillStyle = boxContentLeftColor;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "12px sans-serif";
        ctx.fillText(
          contentLeft,
          offsiteX + boxTitleWidth / 4,
          offsiteY + boxTitleHeight + boxContentHeight / 2
        );
        if (contentRight.includes("-")) {
          ctx.fillStyle = boxContentRightColorG;
        } else {
          ctx.fillStyle = boxContentRightColorR;
        }
        ctx.fillText(
          contentRight,
          offsiteX + (boxTitleWidth / 4) * 3,
          offsiteY + boxTitleHeight + boxContentHeight / 2
        );
      }
    },
    // 构建中间数据
    makeItemMiddle(
      titleColor = "#22C7B1",
      contentData,
      x,
      y,
      leftTotalText,
      leftTotalValue,
      rightTotalText,
      rightTotalValue
    ) {
      for (let i = 0; i < contentData.length; i++) {
        var canvas = document.getElementById("duPont");
        var ctx = canvas.getContext("2d");
        var boxTitleWidth = 150;
        var boxTitleHeight = 20;
        var boxContentHeight = 28;
        var lineWidth = 50;
        var lineTextColor = "#8492A6";
        var offsiteX = x + i * (lineWidth + boxTitleWidth);
        var offsiteY = y;
        var boxTitleColor = titleColor;
        var boxTitleFont = "14px sans-serif";
        var boxTitleFontColor = "#fff";
        var boxContentFont = "12px sans-serif";
        var boxContentColor = "#E9F0F7";
        var boxContentLeftColor = "#1F2D3D";
        var boxContentRightColorR = "#FD5D5A";
        var boxContentRightColorG = "#22C7B1";
        var lineColor = "#516174";
        // 数据
        var title = contentData[i].title;
        var contentLeft = contentData[i].Left;
        var contentRight = contentData[i].Right;
        ctx.fillStyle = boxTitleColor;
        //绘制矩形
        ctx.fillRect(offsiteX, offsiteY, boxTitleWidth, boxTitleHeight);
        ctx.fillStyle = boxContentColor;
        ctx.fillRect(
          offsiteX,
          offsiteY + boxTitleHeight,
          boxTitleWidth,
          boxContentHeight
        );
        ctx.strokeStyle = lineColor;
        ctx.beginPath();
        //绘制横线
        if (i < contentData.length - 1) {
          ctx.strokeStyle = lineColor;
          ctx.moveTo(offsiteX + boxTitleWidth, offsiteY + boxTitleHeight); //把画笔移动到指定的坐标
          ctx.lineTo(
            offsiteX + boxTitleWidth + lineWidth,
            offsiteY + boxTitleHeight
          ); //绘制一条从当前位置到指定坐标200, 50的直线.
          ctx.stroke(); //绘制路径。
        }
        // 绘制右侧数据
        if (contentData[i].rightText) {
          ctx.fillStyle = lineTextColor;
          ctx.font = boxTitleFontColor;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillText(
            contentData[i].rightText,
            offsiteX + boxTitleWidth + lineWidth / 2,
            offsiteY - 2
          );
          ctx.fillText(
            contentData[i].rightValue,
            offsiteX + boxTitleWidth + lineWidth / 2,
            offsiteY + boxTitleHeight / 2 + 2
          );
        }
        // 绘制标题
        ctx.fillStyle = boxTitleFontColor;
        ctx.font = boxTitleFont;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(
          title,
          offsiteX + boxTitleWidth / 2,
          offsiteY + boxTitleHeight / 2
        );
        // 绘制内容左
        ctx.fillStyle = boxContentLeftColor;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "12px sans-serif";
        ctx.fillText(
          contentLeft,
          offsiteX + boxTitleWidth / 4,
          offsiteY + boxTitleHeight + boxContentHeight / 2
        );
        if (contentRight.includes("-")) {
          ctx.fillStyle = boxContentRightColorG;
        } else {
          ctx.fillStyle = boxContentRightColorR;
        }
        ctx.fillText(
          contentRight,
          offsiteX + (boxTitleWidth / 4) * 3,
          offsiteY + boxTitleHeight + boxContentHeight / 2
        );
      }
    },
    // 两条纵线
    makeColumnLine(x, y, length) {
      var canvas = document.getElementById("duPont");
      var ctx = canvas.getContext("2d");
      var lineColor = "#8492A6";
      ctx.strokeStyle = lineColor;
      ctx.moveTo(x, y); //把画笔移动到指定的坐标
      ctx.lineTo(x, y + length); //绘制一条从当前位置到指定坐标200, 50的直线.
      ctx.stroke(); //绘制路径。
    },
    getBook(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 获取书籍列表by id
    getBookById(booksIds) {
      getBookList({
        page: 1,
        pageSize: 50,
        booksIds,
      }).then((res) => {
        this.bookList = res.list;
      });
    },
    // 查询操作
    handlePageChange() {
      this.handleClearCanvas();
      operateDuPont(this.params).then((res) => {
        // 左侧数据拼凑
        this.putContent = [
          {
            title: "现金投放",
            Left: res.cashCost.cashCostAmt + "万",
            Right: res.cashCost.cashCostAmtInc + "%",
          },
          {
            title: "投放人员",
            Left: res.cashCost.putUserCnt + "人",
            Right: res.cashCost.putUserInc,
          },
          {
            title: "投放广告",
            Left: res.adGroup + "条",
            Right: "",
          },
          {
            title: "投放书籍",
            Left: res.cashCost.putBookCnt + "本",
            Right: res.cashCost.putBookInc,
          },
          {
            title: "投放公众号",
            Left: res.cashCost.putChannelCnt + "个",
            Right: res.cashCost.putChannelInc,
          },
        ];
        this.customerService = [
          {
            title: "客服人员",
            Left: res.serviceMsg.operatorCnt + "人",
            Right: res.serviceMsg.operatorInc,
          },
          {
            title: "客服推送公众号",
            Left: res.serviceMsg.putChannelCnt + "个",
            Right: res.serviceMsg.putChannelInc,
          },
          {
            title: "客服推送信息",
            Left: res.serviceMsg.putMsgCnt + "条",
            Right: res.serviceMsg.putMsgInc + "%",
          },
        ];
        this.nowFans = [
          {
            title: "现关粉丝(万)",
            Left: res.fans.utlSubscribeUserCnt + "万",
            value: (res.fans.utlSubscribeUserCnt * 10000).toString(),
            Right: "",
          },
        ];
        // 左2数据拼凑
        this.newUserRecharge = [
          {
            title: "新用户充值人数",
            Left: res.recharge.newRechargeUserCnt,
            Right: res.recharge.newRechargeUserInc + "%",
          },
        ];
        this.oldUserRecharge = [
          {
            title: "老用户充值人数",
            Left: res.recharge.oldRechargeUserCnt,
            Right: res.recharge.oldRechargeUserInc + "%",
          },
        ];
        this.newFans = [
          {
            title: "新增粉丝",
            Left: res.fans.addCumulateUserCnt,
            Right: res.fans.newFansInc + "%",
          },
        ];
        // 中间数据拼凑
        this.todyrecharge = [
          {
            title: "充值人数",
            Left: res.recharge.rechargeUserCnt,
            Right: res.recharge.rechargeUserInc + "%",
            rightText: "人均",
            rightValue: this.formateNumber(
              res.recharge.todayRecharge,
              res.recharge.rechargeUserCnt,
              false
            ),
            // (
            //   res.recharge.todayRecharge.split(",").join("") /
            //   res.recharge.rechargeUserCnt.split(",").join("")
            // ).toFixed(2) + "元",
          },
          {
            title: "今日单量",
            Left: res.recharge.todayOrderCnt,
            Right: res.recharge.todayOrderInc + "%",
            rightText: "单均",
            rightValue: this.formateNumber(
              res.recharge.todayRecharge,
              res.recharge.todayOrderCnt,
              false
            ),
            // (
            //   res.recharge.todayRecharge.split(",").join("") /
            //   res.recharge.todayOrderCnt.split(",").join("")
            // ).toFixed(2) + "元",
          },
          {
            title: "今日充值",
            Left: res.recharge.todayRecharge,
            Right: res.recharge.todayRechargeInc + "%",
          },
        ];

        // 右侧数据拼凑
        this.vipRecharge = [
          {
            title: "会员充值",
            Left: res.recharge.vipRecharge,
            Right: res.recharge.vipRechargeInc + "%",
          },
          {
            title: "非会员充值",
            Left: res.recharge.noVipRecharge,
            Right: res.recharge.noVipRechargeInc + "%",
          },
        ];
        this.registerRecharge = [
          {
            title: "D[2-7]客户充值",
            Left: res.registerRecharge.d2Recharge,
            Right: res.registerRecharge.d2RechargeInc + "%",
            value: res.registerRecharge.d2Recharge,
          },
          {
            title: "D[8-15]客户充值",
            Left: res.registerRecharge.d8Recharge,
            Right: res.registerRecharge.d8RechargeInc + "%",
          },
          {
            title: "	D[16-30]客户充值",
            Left: res.registerRecharge.d16Recharge,
            Right: res.registerRecharge.d16RechargeInc + "%",
          },
          {
            title: "D[31-60]客户充值",
            Left: res.registerRecharge.d31Recharge,
            Right: res.registerRecharge.d31RechargeInc + "%",
          },
          {
            title: "D[60+]客户充值",
            Left: res.registerRecharge.d60Recharge,
            Right: res.registerRecharge.d60RechargeInc + "%",
          },
        ];
        // 右2数据拼凑
        this.newUserRechargeMony = [
          {
            title: "新用户充值",
            Left: res.recharge.todayUserRecharge,
            Right: res.recharge.todayUserRechargeInc + "%",
          },
        ];
        this.oldUserRechargeMony = [
          {
            title: "老用户充值",
            Left: res.recharge.oldUserRecharge,
            Right: res.recharge.oldUserRechargeInc + "%",
          },
        ];
        this.makeItem("#22C7B1", this.putContent, 10, 10, "left");
        this.makeItem("#FF9163", this.customerService, 10, 400, "left");
        this.makeItem("#FFB85E", this.nowFans, 10, 640, "left");
        // 2,1
        this.makeItem(
          "#22C7B1",
          this.newUserRecharge,
          260,
          166,
          "middle",
          0,
          "转换价",
          // (
          //   res.cashCost.cashCostAmt.split(",").join("") /
          //   res.recharge.rechargeUserCnt.split(",").join("")
          // ).toFixed(2),
          this.formateNumber(
            (res.cashCost.cashCostAmt * 10000).toString(),
            res.recharge.newRechargeUserCnt,
            false
          ),
          "占比",
          // (
          //   res.recharge.newRechargeUserCnt.split(",").join("") /
          //   res.recharge.rechargeUserCnt.split(",").join("")
          // ).toFixed(2) *
          //   100 +
          //   "%",
          this.formateNumber(
            res.recharge.newRechargeUserCnt,
            res.recharge.rechargeUserCnt
          )
        );
        // 2,2
        this.makeItem(
          "#FF9163",
          this.oldUserRecharge,
          260,
          478,
          "middle",
          0,
          0,
          0,
          " ",
          // (
          //   res.recharge.oldRechargeUserCnt.split(",").join("") /
          //   res.recharge.rechargeUserCnt.split(",").join("")
          // ).toFixed(2) *
          //   100 +
          //   "%",
          this.formateNumber(
            res.recharge.oldRechargeUserCnt,
            res.recharge.rechargeUserCnt
          )
        );
        this.makeItem(
          "#FFB85E",
          this.newFans,
          260,
          640,
          "middle",
          0,
          "粉丝价",
          // (
          //   res.cashCost.cashCostAmt.split(",").join("") /
          //   res.fans.addCumulateUserCnt.split(",").join("")
          // ).toFixed(2),
          this.formateNumber(
            (res.cashCost.cashCostAmt * 10000).toString(),
            res.fans.addCumulateUserCnt,
            false
          ),
          " ",
          // (
          //   res.fans.addCumulateUserCnt.split(",").join("") /
          //   res.fans.utlSubscribeUserCnt.split(",").join("")
          // ).toFixed(2) *
          //   100 +
          //   "%"
          this.formateNumber(
            res.fans.addCumulateUserCnt,
            (res.fans.utlSubscribeUserCnt * 10000).toString()
          )
        );
        this.makeItemMiddle("#579BF4", this.todyrecharge, 405, 320);

        // 4.1.1
        this.makeItem(
          "#6273E0",
          this.vipRecharge,
          1200,
          126,
          "right",
          0,
          "占比",
          res.recharge.todayUserRecharge
        );
        // 4.1.2
        this.makeItem(
          "#FED65A",
          this.registerRecharge,
          1200,
          320,
          "right",
          0,
          "",
          res.recharge.oldUserRecharge
        );
        // 4.2.1
        this.makeItem(
          "#6273E0",
          this.newUserRechargeMony,
          950,
          166,
          "middle",
          0,
          "占比",
          // (
          //   res.recharge.todayUserRecharge.split(",").join("") /
          //   res.recharge.todayRecharge.split(",").join("")
          // ).toFixed(2) *
          //   100 +
          //   "%",
          this.formateNumber(
            res.recharge.todayUserRecharge,
            res.recharge.todayRecharge
          )
        );
        // 4.2.2
        this.makeItem(
          "#FED65A",
          this.oldUserRechargeMony,
          950,
          476,
          "middle",
          0,
          "占比",
          // (
          //   res.recharge.oldUserRecharge.split(",").join("") /
          //   res.recharge.todayRecharge.split(",").join("")
          // ).toFixed(2) *
          //   100 +
          //   "%",
          this.formateNumber(
            res.recharge.oldUserRecharge,
            res.recharge.todayRecharge
          )
        );
        // 左纵线
        this.makeColumnLine(460, 190, 130);
        this.makeColumnLine(460, 367, 298);
        // 右纵线
        this.makeColumnLine(900, 190, 130);
        this.makeColumnLine(900, 367, 132);
      });
    },
    // 重置
    handleReset() {
      this.params.originPlatform = null;
      this.params.accountChannelId = null;
      this.params.booksId = null;
      this.sortName = null;
      this.sortDesc = null;
      this.handlePageChange();
    },
    // 清除canvas
    handleClearCanvas() {
      var canvas = document.getElementById("duPont");
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    // 处理NaN 及循环数
    formateNumber(divisor, dividend, percent = true) {
      let tmpResult = (
        divisor.split(",").join("") / dividend.split(",").join("")
      ).toFixed(3);
      // 处理是否为NaN
      if (tmpResult.toString() === "NaN") {
        tmpResult = 0.0;
      }

      if (percent) {
        tmpResult = tmpResult * 100;
        // 处理超长数字

        let tmpResultArr = tmpResult.toString().split(".");
        if (tmpResultArr.length > 1 && tmpResultArr[1].length > 2) {
          tmpResultArr[1] = tmpResultArr[1].slice(0, 2);
          tmpResult = tmpResultArr.join(".");
        }
        tmpResult = Number(tmpResult).toFixed(1) + "%";
      } else {
        tmpResult = Number(tmpResult).toFixed(1) + "元";
      }
      return tmpResult;
    },
  },
  created() {
    this.params = {
      ...this.lastParams,
    };
  },
  mounted() {
    this.getBookById(this.params.booksId);
    this.handlePageChange();
  },
};
</script>

<style scoped lang="scss">
.duPont-box {
  width: 1350px;
  height: 700px;
  margin: 0 auto;
}
.filter-box .row{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
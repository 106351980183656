<template>
  <div class="puter-detial">
    <el-drawer
      title="派单信息"
      class="puter-detail"
      :visible.sync="showDetail"
      :destroy-on-close="true"
      size="85%"
      :before-close="
        () => {
          $emit('closeDetail');
          showDetail = false;
        }
      "
    >
      <div>
        <!-- 派单信息 -->
        <el-card class="box-card">
          <!-- <div slot="header" class="clearfix">
            <span>派单信息</span>
          </div> -->
          <div class="put-box">
            <div class="tag-box">
              <el-tag>投手：{{ detailData.putUserName }}</el-tag>
              <el-tag>书籍分类：{{ detailData.booksCategoryName }}</el-tag>
              <el-tag>书籍：{{ detailData.bookName }}</el-tag>
              <el-tag>素材分类：{{ detailData.deliveryCategoryName }}</el-tag>
              <el-tag>上传时间：{{ detailData.createdTime }}</el-tag>
              <el-tag>文案：{{ detailData.content }}</el-tag>
              <el-tag>想法：{{ detailData.idea }}</el-tag>
            </div>
            <div class="img-box">
              <div
                class="source-img"
                v-for="(item, index) in uploadList"
                :key="index"
              >
                <el-popover placement="top" trigger="hover" width="424">
                  <div class="origin-img">
                    <video
                      width="424"
                      v-if="item.type === 1"
                      controls
                      :src="item.src"
                    ></video>
                    <img v-else style="width: 424px" :src="item.src" alt="" />
                  </div>
                  <div slot="reference">
                    <video
                      v-if="item.type === 1"
                      width="180"
                      height="140"
                      controls
                      :src="item.src"
                    ></video>
                    <img
                      v-else
                      style="width: 180px; height: 140px"
                      :src="item.src"
                      alt=""
                    />
                  </div>
                </el-popover>
                <!-- <video
                  v-if="item.type === 1"
                  width="180"
                  height="140"
                  controls
                  :src="item.src"
                ></video>
                <img v-else :src="item.src" alt="" /> -->
              </div>
            </div>
          </div>
        </el-card>
        <!-- 接单列表 -->
        <el-card class="box-card" style="border: none">
          <!-- <div slot="header" class="clearfix">
            <span>接单信息</span>
          </div> -->
          <!-- 用户搜索 -->
          <div class="row search-row">
            <el-button
              type="primary"
              @click="handleAddDesigner"
              style="margin-right: 10px"
            >
              新增
            </el-button>
            <!-- 单独用户 -->
            <el-select
              style="width: 150px"
              v-model="adminUserId"
              filterable
              clearable
              placeholder="上传者"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button
              type="primary"
              @click="handleSearchUser"
              style="margin-left: 10px"
            >
              搜索
            </el-button>
            <el-button type="info" @click="handleReset"> 重置 </el-button>
          </div>
          <!-- 美工个体 -->
          <div class="designer-container">
            <div
              class="designer-item"
              v-for="(item, index) in artList"
              :key="index"
            >
              <div class="media-container">
                <div class="media-info">
                  <h5>用户：{{ item.artUserName }}</h5>
                  <p>上传时间：{{ item.createdTime }}</p>
                  <el-popover placement="top" title="文案" trigger="hover">
                    <div>
                      {{ item.artContent }}
                    </div>
                    <div class="line-overflow" slot="reference">
                      <p>文案: {{ item.artContent }}</p>
                    </div>
                  </el-popover>
                </div>
                <div class="media-box">
                  <template v-if="item.artImg">
                    <template v-for="(item2, index2) in item.artImg.split(',')">
                      <el-popover
                        placement="top"
                        trigger="hover"
                        :key="index2"
                        width="424"
                      >
                        <div class="origin-img">
                          <img
                            class="media"
                            style="width: 400px"
                            :src="item2"
                            alt=""
                          />
                        </div>
                        <div slot="reference">
                          <img class="media" :src="item2" alt="" />
                        </div>
                      </el-popover>
                    </template>
                    <!-- <img
                      class="media"
                      v-for="(item2, index2) in item.artImg.split(',')"
                      :key="index2"
                      :src="item2"
                      alt=""
                    /> -->
                  </template>
                  <template v-if="item.artMedia">
                    <template
                      v-for="(item2, index2) in item.artMedia.split(',')"
                    >
                      <el-popover
                        placement="top"
                        trigger="hover"
                        :key="index2 + 'video'"
                      >
                        <div class="origin-img">
                          <video
                            class="media"
                            width="400"
                            controls
                            :src="item2"
                          ></video>
                        </div>
                        <div slot="reference">
                          <video class="media" controls :src="item2"></video>
                        </div>
                      </el-popover>
                    </template>
                    <!-- <video
                      class="media"
                      controls
                      v-for="(item2, index2) in item.artMedia.split(',')"
                      :key="index2"
                      :src="item2"
                    ></video> -->
                  </template>
                </div>
                <div class="button-row">
                  <el-button
                    class="button-small"
                    @click="handleShowOrderDetail(item)"
                    >详情</el-button
                  >
                  <el-button
                    class="button-small"
                    @click="handleplantDetail(item)"
                    >计划详情</el-button
                  >
                  <el-button
                    type="primary"
                    class="button-small"
                    @click="handleEditOrder(item)"
                    >编辑</el-button
                  >
                  <el-button
                    type="danger"
                    class="button-small"
                    @click="handleDeleteOrder(item)"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <!-- 编辑抽屉 -->
      <el-drawer
        :title="`${isEdit ? '编辑接单' : '新增接单'}`"
        :visible.sync="showEdit"
        :destroy-on-close="true"
        :append-to-body="true"
        :before-close="handleCancelAddOrder"
        size="50%"
      >
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          class="order-form"
          label-width="100px"
          v-if="showEdit"
        >
          <!-- 上传 -->
          <el-form-item label="素材" prop="img">
            <el-tabs
              :before-leave="handleuploadTypeChange"
              type="border-card"
              v-model="uploadType"
            >
              <!-- 上传图片 -->
              <el-tab-pane label="上传图片" name="image">
                <span style="color: #ccc; margin-left: 10px"
                  >图片支持png,jpeg,格式</span
                >
                <input
                  type="file"
                  accept="image/gif, image/jpeg, image/png, image/jpg"
                  style="display: none"
                  ref="imgOrderUpload"
                  @change="handleFileChange"
                />
                <div class="source-box">
                  <div
                    class="source-img"
                    v-for="(item, index) in orderUploadList"
                    :key="index"
                  >
                    <img :src="item.src" alt="" />
                    <div class="mask-hover">
                      <i
                        class="iconfont icon-lajitong"
                        style="color: #fff"
                        @click="handleDelSource(index)"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="source-img"
                    @click="uploadImg"
                    v-if="orderUploadList.length < 6"
                  >
                    <div class="add-surce">
                      <i class="el-icon-plus"></i>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <!-- 上传视频 -->
              <el-tab-pane label="上传视频" name="video">
                <span style="color: #ccc; margin-left: 10px"
                  >视频支持avi,mp4,mov格式</span
                >
                <input
                  type="file"
                  accept=".avi,.mp4,.mov"
                  style="display: none"
                  ref="videoOrderUpload"
                  @change="handleFileChange"
                />
                <div class="video-progress" v-if="showProcess">
                  <el-progress
                    :show-text="false"
                    :percentage="videoPercent"
                  ></el-progress>
                </div>
                <div class="source-box">
                  <div
                    class="source-img"
                    v-for="(item, index) in orderUploadList"
                    :key="index"
                  >
                    <video
                      width="180"
                      height="140"
                      controls
                      :src="item.src"
                    ></video>
                    <div class="mask-hover">
                      <i
                        class="iconfont icon-lajitong"
                        style="color: #fff"
                        @click="handleDelSource(index)"
                      ></i>
                    </div>
                  </div>
                  <div
                    class="source-img"
                    @click="uploadImg"
                    v-if="!showProcess"
                  >
                    <div class="add-surce">
                      <i class="el-icon-plus"></i>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>

          <el-form-item
            v-if="isEdit"
            v-show="false"
            label="派单id"
            prop="deliveryId"
          >
            <el-input
              style="width: 150px"
              type="number"
              v-model="ruleForm.deliveryId"
            ></el-input>
          </el-form-item>
          <el-form-item label="文案" prop="content">
            <el-input
              style="width: 500px"
              type="textarea"
              v-model="ruleForm.content"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleAddOrder">确定</el-button>
            <el-button type="info" @click="handleCancelAddOrder"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-drawer>
      <!-- 详情抽屉 -->
      <el-drawer
        title="接单详情"
        :visible.sync="showOrderDetail"
        :destroy-on-close="true"
        :append-to-body="true"
        size="70%"
      >
        <div class="order-detail">
          <el-card class="box-card" style="border: none">
            <div class="order-detail-info">
              <div class="tag-box">
                <el-tag style="margin: 5px"
                  >文案：{{ orderDetail.artContent }}</el-tag
                >
              </div>
              <el-form
                :model="DetailruleForm"
                ref="ruleForm"
                :rules="rules"
                class="order-form"
                label-width="100px"
              >
                <!-- 上传 -->
                <el-form-item label-width="50px" label="素材" prop="img">
                  <template v-if="orderDetail.artImg">
                    <div class="source-box">
                      <div
                        class="source-img"
                        v-for="(item, index) in orderDetail.artImg.split(',')"
                        :key="index"
                      >
                        <el-popover
                          placement="top"
                          trigger="hover"
                          :key="index"
                          width="424"
                        >
                          <div class="origin-img">
                            <img style="width: 424px" :src="item" alt="" />
                          </div>
                          <div slot="reference">
                            <img :src="item" alt="" />
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="orderDetail.artMedia">
                    <div class="source-box">
                      <div
                        class="source-img"
                        v-for="(item, index) in orderDetail.artMedia.split(',')"
                        :key="index"
                      >
                        <el-popover
                          width="424"
                          placement="top"
                          trigger="hover"
                          :key="index"
                        >
                          <div class="origin-img">
                            <video width="424" controls :src="item"></video>
                          </div>
                          <div slot="reference">
                            <video
                              width="180"
                              height="140"
                              controls
                              :src="item"
                            ></video>
                          </div>
                        </el-popover>
                        <!-- <video
                          width="180"
                          height="140"
                          controls
                          :src="item"
                        ></video> -->
                      </div>
                    </div>
                  </template>
                </el-form-item>
              </el-form>
            </div>
          </el-card>

          <!-- 关联计划 -->
          <el-card class="box-card" style="border: none">
            <div class="relation-plan">
              <!-- 用户搜索 -->
              <div class="row search-row">
                <el-button
                  type="primary"
                  @click="handleAddRelationPlan"
                  style="margin-right: 10px"
                >
                  新增
                </el-button>
                <el-input
                  style="width: 150px"
                  v-model="campaignId"
                  placeholder="请输入计划id"
                ></el-input>
                <el-button
                  type="primary"
                  @click="handleGetRelationPlan"
                  style="margin-left: 10px"
                >
                  搜索
                </el-button>
                <el-button type="info" @click="handleResetRelationPlan">
                  重置
                </el-button>
              </div>
              <!-- 关联计划table -->
              <el-table
                :data="relationPlanList"
                height="480px"
                style="width: 100%"
              >
                <el-table-column prop="id" label="列表id"> </el-table-column>
                <el-table-column prop="campaignId" label="计划id">
                </el-table-column>
                <el-table-column prop="campaignUserName" label="操作者">
                </el-table-column>
                <el-table-column prop="createdTime" label="操作时间">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      class="button-small"
                      @click="handleEditRelationPlan(scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      type="danger"
                      class="button-small"
                      @click="handleDeleteRelationPlan(scope.row)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                style="margin: 10px 0; text-align: right"
                background
                @current-change="handleRelationPlanPageChange"
                :current-page="relationPlanPage"
                :page-size="relationPlanPageSize"
                layout="total, prev, pager, next, jumper"
                :total="relationPlanTotal"
              >
              </el-pagination>
            </div>
          </el-card>
        </div>
      </el-drawer>
    </el-drawer>
    <put-project-detail :show.sync="showProjectDetail" :info="projectInfo">
    </put-project-detail>
  </div>
</template>

<script>
import {
  getPutDeliveryArt,
  compressUpload,
  addPutDeliveryArt,
  changePutDeliveryArt,
  deletePutDeliveryArt,
  getputDeliveryArtCampaign,
  addputDeliveryArtCampaign,
  changeputDeliveryArtCampaign,
  deleteputDeliveryArtCampaign,
} from "@/api/novelPut";
import PutProjectDetail from "./put-project-detail.vue";
import { mapGetters } from "vuex";
import PutDecisionMaker from "../../home/board/put/put-decision-maker.vue";
export default {
  name: "puterDetail",
  props: {
    showDetailF: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: new Object(),
    },
  },
  data() {
    return {
      showDetail: false,
      uploadList: [],
      artList: [],
      adminUserId: "",
      isEdit: false,
      orderId: null,
      showEdit: false,
      ruleForm: {},
      rules: {},
      uploadType: "image",
      orderUploadList: [],
      showProcess: false,
      showOrderDetail: false,
      orderDetail: {},
      DetailruleForm: {},
      // 关联计划相关
      relationPlanPage: 1,
      relationPlanPageSize: 15,
      relationPlanDeliveryArtId: null,
      campaignId: null,
      relationPlanTotal: 0,
      relationPlanList: [],
      showProjectDetail: false,
      projectInfo: null,
    };
  },
  computed: {
    ...mapGetters(["userList"]),
    videoPercent() {
      return this.$store.state.uploadPercent;
    },
  },
  watch: {
    videoPercent: {
      handler(newV) {
        this.showProcess = newV !== 100;
      },
    },
    showDetailF: function (newV) {
      this.showDetail = newV;
      // 接单查询 美工
      this.handleSearchUser();
      this.uploadList = [];
      if (this.detailData.deliveryExampleList) {
        this.detailData.deliveryExampleList.forEach((item) => {
          if (item.mediaUrl) {
            this.uploadList.push({
              type: 1,
              src: item.mediaUrl,
            });
          } else if (item.imgUrl) {
            this.uploadList.push({
              type: 0,
              src: item.imgUrl,
            });
          }
        });
      }
    },
  },
  methods: {
    // 关联计划相关=================>
    // ============================>
    // 删除关联计划
    handleDeleteRelationPlan(item) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(
        () => {
          deleteputDeliveryArtCampaign(item.id).then((res) => {
            this.$message.success("删除成功");
            this.handleGetRelationPlan();
          });
        },
        () => {}
      );
    },
    // 编辑关联计划
    handleEditRelationPlan(item) {
      this.$prompt("请输入计划id", "提示", {
        inputType: "number",
        inputValue: item.campaignId,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: "请输入数字",
      })
        .then(({ value }) => {
          if (!value) {
            this.$message.error("计划id不能为空");
            return;
          }
          changeputDeliveryArtCampaign(item.id, {
            campaignId: value,
          }).then((res) => {
            this.$message.success("修改计划成功");
            this.handleGetRelationPlan();
          });
        })
        .catch(() => {});
    },
    // 新增关联计划
    handleAddRelationPlan() {
      this.$prompt("请输入计划id", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: "请输入数字",
      })
        .then(({ value }) => {
          if (!value) {
            this.$message.error("计划id不能为空");
            return;
          }
          addputDeliveryArtCampaign({
            deliveryArtId: this.relationPlanDeliveryArtId,
            campaignId: value,
          }).then((res) => {
            this.$message.success("新增计划成功");
            this.handleGetRelationPlan();
          });
        })
        .catch(() => {});
    },
    // 关联计划页码切换
    handleRelationPlanPageChange(page) {
      this.relationPlanPage = page;
      this.handleGetRelationPlan();
    },
    // 重置关联计划
    handleResetRelationPlan() {
      this.relationPlanPage = 1;
      this.campaignId = null;
      this.handleGetRelationPlan();
    },
    // 获取关联计划列表
    handleGetRelationPlan() {
      getputDeliveryArtCampaign({
        page: this.relationPlanPage,
        pageSize: this.relationPlanPageSize,
        deliveryArtId: this.relationPlanDeliveryArtId,
        campaignId: this.campaignId,
      }).then((res) => {
        this.relationPlanTotal = res.total;
        this.relationPlanList = res.list;
      });
    },
    // 接单相关=================>
    // ============================>
    // 获取接单列表
    handleSearchUser() {
      getPutDeliveryArt({
        deliveryId: this.detailData.id,
        adminUserId: this.adminUserId,
      }).then((res) => {
        this.artList = res;
      });
    },
    // 重置接单
    handleReset() {
      this.adminUserId = "";
      this.handleSearchUser();
    },
    // 编辑接单
    handleEditOrder(item) {
      this.ruleForm = {};
      this.$set(this.ruleForm, "content", item.artContent);
      this.orderId = item.id;
      this.orderUploadList = [];
      if (item.artMedia) {
        this.uploadType = "video";
        item.artMedia.split(",").forEach((element) => {
          this.orderUploadList.push({
            type: 1,
            src: element,
          });
        });
      } else if (item.artImg) {
        this.uploadType = "image";
        item.artImg.split(",").forEach((element) => {
          this.orderUploadList.push({
            type: 0,
            src: element,
          });
        });
      }
      this.isEdit = true;
      this.showEdit = true;
    },
    DeleteEditDesigner(item) {},
    // 取消接单提交
    handleCancelAddOrder() {
      this.ruleForm = {};
      this.orderUploadList = [];
      this.isEdit = false;
      this.showEdit = false;
    },
    // 接单提交
    handleAddOrder() {
      if (this.orderUploadList.length === 0 && !this.ruleForm.content) {
        this.$message.error("请选择素材或填写文案");
        return false;
      }
      // // 编辑接单提交
      let tmpart = [];
      this.orderUploadList.forEach((item) => {
        tmpart.push(item.src);
      });
      if (this.isEdit) {
        if (this.uploadType === "image") {
          changePutDeliveryArt(this.orderId, {
            artContent: this.ruleForm.content,
            artImg: tmpart.join(","),
          }).then((res) => {
            this.$message.success("修改成功");
            this.handleCancelAddOrder();
            this.handleSearchUser();
          });
        } else if (this.uploadType === "video") {
          changePutDeliveryArt(this.orderId, {
            artContent: this.ruleForm.content,
            artMedia: tmpart.join(","),
          }).then((res) => {
            this.$message.success("修改成功");
            this.handleCancelAddOrder();
            this.handleSearchUser();
          });
        }
      } else {
        if (this.uploadType === "image") {
          addPutDeliveryArt({
            deliveryId: this.ruleForm.deliveryId,
            artContent: this.ruleForm.content,
            artImg: tmpart.join(","),
          }).then((res) => {
            this.$message.success("新增成功");
            this.handleCancelAddOrder();
            this.handleSearchUser();
          });
        } else if (this.uploadType === "video") {
          addPutDeliveryArt({
            deliveryId: this.ruleForm.deliveryId,
            artContent: this.ruleForm.content,
            artMedia: tmpart.join(","),
          }).then((res) => {
            this.$message.success("新增成功");
            this.handleCancelAddOrder();
            this.handleSearchUser();
          });
        }
      }
    },
    // 新增接单
    handleAddDesigner() {
      this.$set(this.ruleForm, "deliveryId", this.detailData.id);
      this.uploadType = "image";
      this.isEdit = false;
      this.showEdit = true;
    },
    // 接单详情
    handleShowOrderDetail(item) {
      this.showOrderDetail = true;
      this.relationPlanDeliveryArtId = item.id;
      this.orderDetail = item;
      this.handleGetRelationPlan();
    },
    handleCancelOrderDetail() {
      this.showOrderDetail = false;
      this.orderDetail = {};
    },
    // 计划详情
    handleplantDetail(item) {
      this.projectInfo = JSON.parse(JSON.stringify(item));
      this.showProjectDetail = true;
    },
    // 删除接单
    handleDeleteOrder(item) {
      // 提示确定
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletePutDeliveryArt(item.id).then((res) => {
          this.$message.success("删除成功");
          this.handleSearchUser();
        });
      });
    },
    // 上传文件类型切换
    handleuploadTypeChange(activeName, oldActive) {
      if (this.orderUploadList.length > 0) {
        return this.$confirm(
          "不支持视频图片混合上传,切换上传类型会丢失已上传数据，是否切换?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.orderUploadList = [];
          return true;
        });
      }
    },
    // 添加视频
    async handleFileChange(e) {
      const tmp = e.target ? e.target.files[0] : e.srcElement.files[0];
      const targetFile = e.target ? e.target.files[0] : e.srcElement.files[0];
      if (["video/avi", "video/mp4", "video/mov"].includes(targetFile.type)) {
        this.status = 1;
        const res = await compressUpload({ file: targetFile });
        this.orderUploadList.push({
          type: 1,
          src: res,
        });
        this.$refs.videoOrderUpload.value = "";
      } else {
        this.videoSrc = "";
        this.status = 0;
        const fr = new FileReader();
        fr.readAsDataURL(targetFile);
        fr.onload = (event) => {
          const image = new Image();
          image.src = event.currentTarget.result;
          image.onload = () => {
            //   const { width, height } = image
            compressUpload({ file: targetFile }).then((res) => {
              this.orderUploadList.push({
                type: 0,
                src: res,
              });
              this.$refs.imgOrderUpload.value = "";
            });
          };
        };
      }
    },
    // 删除上传视频 图片
    handleDelSource(index) {
      this.orderUploadList.splice(index, 1);
    },
    // 点击新增
    uploadImg() {
      if (this.uploadType === "image") {
        this.$refs.imgOrderUpload.click();
      } else {
        this.$refs.videoOrderUpload.click();
      }
    },
  },
  created() {},
  components: {
    PutProjectDetail,
    PutDecisionMaker,
  },
};
</script>

<style lang="scss" scoped>
.put-box{
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  padding: 5px;
  .tag-box{
    .el-tag{
      margin: 5px;
      font-size: 14px;
    }
  }
  .img-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 5px;
    .source-img{
    position: relative;
    margin-right: 5px;
    margin-top: 5px;
    width: 180px;
    height: 140px;
    img {
    width: 100%;
    height: 100%;
  }
  }
  }
}

.search-row{
  margin-bottom: 5px;
  padding: 5px;
}
// 美工展示图
.designer-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .media-box{
    height: 220px;
  }
  .designer-item{
    width: 100%;
    height: 120px;
    border: 1px solid rgba(0,0,0,.125);
    padding: 5px;
    border-radius: 5px;
    margin: 5px;
    p{
      font-size: 15px;
      color: #676767;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .media-container{
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .media-info{
        margin-right: 10px;
        width: 500px;
        h5{
        font-size: 15px;
        font-weight: normal;
        line-height: 30px;
        color: #676767;
        }
        p{
          font-size: 15px;
          color: #676767;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .media-box{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100px;
        width: 780px;
        .media{
        width: 118px;
        height: 100px;
        margin: 5px;
      }
      }
      .button-row{
        width: 280px;
        margin-top: 5px;
        margin-left: auto;
      }
    }

    .add-surce{
      cursor: pointer;
      width: 100%;
      height: 100%;
      // border: 1px dashed #ccc;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        font-size: 40px;
      }
    }
  }
}

// 美工新增编辑
/deep/ .el-tabs--border-card{
  // border-bottom: none !important;
  box-shadow: none !important;
}
.order-form{
  .source-box{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .source-img:hover .mask-hover{
        display: flex;
    }
    .source-img{
      width: 180px;
      height: 140px;
      margin: 5px;
      position: relative;
      img{
        width: 180px;
        height: 140px;
      }
      .mask-hover{
        cursor: pointer;
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.5);
        align-items: center;
        justify-content: center;
      }
    }
  }
  .add-surce{
    cursor: pointer;
    width: 180px;
    height: 140px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-size: 20px;
    }
  }
}
// 接单详情相关
.order-detail{
  /deep/ .el-card__body{
    padding: 10px;
  }
  .order-detail-info,.relation-plan{
    padding: 5px;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 5px;
    }
  .tag-box{
    .el-tag{
      font-size: 14px;
    }
  }
}
// 抽屉样式修改
.puter-detail /deep/ .el-drawer__header{
  margin-bottom:0 !important;
}
.box-card{
  border: none !important;
}
</style>
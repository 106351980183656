import request from './config'

export function getClassifyList (params) {
  return request.get('/category', { params })
}

export function editClassify ({ id, ...data }) {
  if (id) {
    return request.put(`/category/${id}`, data)
  } else {
    return request.post('/category', data)
  }
}

export function changeClassify (id, status) {
  return request.put(`/category/enable/${id}`, { status })
}

export function getTitleList (params) {
  return request.get('/title', { params })
}

export function editTitle ({ id, ...data }) {
  if (id) {
    return request.put(`/title/${id}`, data)
  } else {
    return request.post('/title', data)
  }
}

export function deleteTitle (id) {
  return request.delete(`/title/${id}`)
}

export function getImageList (params) {
  return request.get('/picture', { params })
}

export function addImage (params) {
  return request.post('/picture', params)
}

export function deleteImage (id) {
  return request.delete(`/picture/${id}`)
}

export function getDescriptionList (params) {
  return request.get('/desc', { params })
}

export function editDescription ({ id, ...data }) {
  if (id) {
    return request.put(`/desc/${id}`, data)
  } else {
    return request.post('/desc', data)
  }
}

export function deleteDescription (id) {
  return request.delete(`/desc/${id}`)
}
// 查询分类组
export function getClassifyGroup (id) {
  return request.get(`/fields/group/category/${id}`)
}

// 模板管理-新增模板（客服消息）

export function addMessageModule (params) {
  return request.post('/customer/message/module', params)
}

// 修改模板

export function editMessageModule (id, params) {
  return request.put(`/customer/message/module/${id}`, params)
}

// 删除模板

export function deleteMessageModule (id) {
  return request.delete(`/customer/message/module/${id}`)
}

// 查询模板

export function getMessageModule (params) {
  return request.get('/customer/message/module/', { params })
}

// 修改图片标题

export function changePictureTitle (id, {title,categoryId}) {
  return request.put(`/picture/${id}`, { ...(title && {title}),...(categoryId && {categoryId}) })
}

// 查询书籍状态
export function getBookStatus (params) {
  return request.get('/books/status', { params })
}

// 新增书籍状态
export function addBookStatus (params) {
  return request.post('/books/status', params)
}

// 修改书籍状态
export function changeBookStatus (id, params) {
  return request.put(`/books/status/${id}`, params)
}

// 删除书籍状态
export function deleteBookStatus (id) {
  return request.delete(`/books/status/${id}`)
}

// 未支付用户列表

export function unPaidUserList (params) {
  return request.get('/user/unpaid', { params })
}

// 未支付客服消息列表

export function getUnPaidMegList (params) {
  return request.get('/unpaid/message', { params })
}

// 未支付客服消息-新增

export function addUnPaidMsg (params) {
  return request.post('/unpaid/message', params)
}

// 修改
export function editUnPaidMsg (id, params) {
  return request.put(`/unpaid/message/${id}`, params)
}

// 删除

export function delUnPaidMsg (ids) {
  return request.delete(`/unpaid/message/${ids}`)
}

// 复制

export function copyUnPaidMsg (ids, params) {
  return request.put(`/unpaid/message/copy/${ids}`, params)
}

// 修改状态
export function editUnPaidStatus (id, status) {
  return request.put(`/unpaid/message/status/${id}?status=${status}`,)
}
